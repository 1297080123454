import {
  Button,
  FormLabel,
  Grid,
  Paper,
  TextField
} from "@mui/material";
import moment from "moment";
import React from "react";
import Dropzone from "react-dropzone";
import {
  ReactDataGrid_i18n,
  formStyles,
  handleServerError,
  loadDataError,
  showError
} from "../components/common";

import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import { withStyles } from "@mui/styles";
import "react-toastify/dist/ReactToastify.css";
/*----------------------Cac control trong project su dung den trong form----------------*/
import ButtonTimKiem from "../components/ButtonTimKiem";
import SimpleDialog from "../components/SimpleDialog";
import CodeEditDialog from "./CodeEditDialog";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/

const fieldLabels = englishUI
  ? {
    Code: "Code:",
    CodeName: "Description:"
  }
  : {
    Code: "Code:",
    CodeName: "Mô tả:"
  };

const buttonLabels = englishUI
  ? {
    btnTimKiem: "Search",
    btnThem: "Add",
    btnEdit: "Edit",
    btnDelete: "Delete",
    btnImport: "Import",
    btnExport: "Export",
  }
  : {
    btnTimKiem: "Tìm kiếm",
    btnThem: "Thêm",
    btnEdit: "Sửa",
    btnDelete: "Xóa",
    btnImport: "Nhập",
    btnExport: "Xuất"
  };
/*-----------------------Dinh nghia cac column trong grid -----------------*/
const default_gridColumns = [
  {
    name: "Code",
    type: "string",
    headerAlign: "center",
    defaultWidth: 150,
    header: englishUI ? "Code" : "Code",
    render: ({ data }) => data.Code
  },
  {
    name: "CodeName",
    type: "string",
    headerAlign: "center",
    flex: 1,
    header: englishUI ? "Description" : "Mô tả",
    render: ({ data }) => <div style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{data.CodeName}</div>
  },
  {
    name: "NgayCN",
    type: "date",
    headerAlign: "center",
    defaultWidth: 150,
    format: "HH:mm DD/MM/YYYY",
    header: englishUI ? "Updated time" : "Ngày CN",
    render: (props) =>
      props.data.NgayCN
        ? moment(props.data.NgayCN).format("HH:mm DD/MM/YYYY")
        : null,
  },
];
/*-----------------------Cac bien su dung trong form ----------------------*/
const defaultSortInfo = { name: "Code", dir: 0, type: "string" };
//tra ve index column tuy theo sort infor
const getSortColumnIndex = (columns, sortInfor) => {
  var find_index = 0;
  for (var i = 0; i < columns.length; i++) {
    var item = columns[i];
    if (item.name == sortInfor.name) {
      find_index = i;
      break;
    }
  }
  return find_index;
};

//ham filter su dung cho combobox

class CodePage extends React.Component {
  selectionCounter = 0; //dem so lan click chon row trong grid
  lastClick = 0; //danh dau lan click truoc do' cua grid de xu ly double click
  constructor(props) {
    super(props);
    this.columns_grid = default_gridColumns;
    //load lai thong tin giao dien cua phien lam viec truoc do
    this.state = {
      dataChanged: false, //du lieu co su thay doi hay khong
      loadOnStart: true, //co load du lieu ngay khi mo form khong
      errorMessage: "", //mo ta loi neu co
      errorLog: "", //mo ta chi tiet loi
      DanhMuc: {}, //luu cac du lieu danh muc su dung trong form
      //searching
      search_loading: false,
      search_result: { data: [], count: 0 }, //ket qua tim kiem
      search_dataSource: [],
      search_error: false, //search co loi khong
      lastSortInfo: defaultSortInfo, //vi grid co trang thai sortinfo = null nen phai co cai nay
      sortInfo: defaultSortInfo, //thong tin sap xep cua grid
      //chua cac tieu chi tim kiem
      after:
        props.value === undefined //gia tri truyen vao tu form cha
          ? {
            PageIndex: 1,
            PageSize: 25,
            Ascending: true,
            SortBy: getSortColumnIndex(this.columns_grid, defaultSortInfo), //index column duoc sap xep, anh xa giua ten cot duoc chon va index cua colmn trong grid
            //danh sach cac tieu chi tim kiem
            Code: null,
            CodeName: null
          }
          : props.value, //thong tin tieu chi truyen vao tu form cha
    };
  }
  //ghi nhan 1 tieu chi tim kiem
  saveField = (fieldName, value) => {
    var s = this.state;
    if (fieldName !== "PageIndex") {
      s.after.PageIndex = 1;
    } else {
      if (s.after.PageIndex === value) {
        return; //ko co su thay doi thi bo qua
      }
    }
    s.after[fieldName] = value;
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    if (
      fieldName !== "PageSize" &&
      fieldName !== "PageIndex" &&
      fieldName !== "SortBy"
    ) {
      this.setMyState(this.state);
    } else {
      //lien quan den phan trang hoac sap xep thi reload
      this.setMyState(this.state, () => {
        this.doSearch();
      });
    }
  };
  //duoc su dung neu form hien thi trong che do MDI
  setMyState = (state, callback) => {
    this.setState(state, () => {
      //su dung cho muc dich MDI form
      if (this.props.onStateChanged) {
        this.props.onStateChanged(this.state);
      }
      //reload data
      if (callback) {
        callback();
      }
    });
  };
  //su kien form load
  componentDidMount() {
    this.doSearch();
  }
  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
  static getDerivedStateFromProps() {
    return null;
  }
  shouldComponentUpdate() {
    return true;
  }

  componentDidUpdate() { }
  //khi thay doi sap xep cot
  onSortInfoChange = (value) => {
    const newSort = value
      ? { type: value.type, name: value.name, dir: value.dir }
      : value;
    //ghi nhan vao sort by
    this.setState(
      {
        lastSortInfo: this.state.sortInfo
          ? this.state.sortInfo
          : this.state.lastSortInfo,
        sortInfo: newSort,
      },
      () => this.doSearch()
    );
  };
  loadData = ({ skip, limit }) => {
    var serverSortInfo = this.state.sortInfo
      ? this.state.sortInfo
      : this.state.lastSortInfo;

    var loader = new Promise((resolve) => {
      server
        .post("Chiphi/DoRequest", {
          Function: "Proc_DM_Codes_Search",
          ThamSo: {
            ...this.state.after,
            PageIndex: skip / limit + 1,
            PageSize: limit,
            SortBy: getSortColumnIndex(this.columns_grid, serverSortInfo),
            Ascending: serverSortInfo.dir == 1,
          },
        })
        .then((response) => {
          this.setState({
            search_loading: false,
            search_error: false,
            search_result: {
              data: response.DataList,
              count: response.DataCount[0].Total,
            },
            rowSelected: undefined,
            rowDoubleClicked: undefined
          });
          resolve({
            data: response.DataList,
            count: response.DataCount[0].Total,
          });
        })
        .catch((error) => {
          var message = "";
          var log = "";
          try {
            var obj = JSON.parse(error);
            message = obj.message;
            log = obj.traceLog;
          } catch (e) {
            message = error + "";
          }
          this.setState({
            search_loading: false,
            search_error: true,
            errorMessage: message,
            errorLog: log,
            search_result: { data: [], count: 0 },
            rowSelected: undefined,
          });
          resolve({ data: [], count: 0 });
        });
    });
    return loader;
  };
  //Thuc hien search
  doSearch = () => {
    var loader = this.loadData({
      skip: (this.state.after.PageIndex - 1) * this.state.after.PageSize,
      limit: this.state.after.PageSize,
      sortInfo: this.state.sortInfo,
    });
    this.setState({
      search_loading: true,
      search_dataSource: loader, //chi load sau khi co danh muc
      selectdReecord: {},
    });
  };

  //thuc hien import file
  doImport(files) {
    if (Array.isArray(files) && files.length == 1) {
      try {
        var fr = new FileReader();
        fr.onload = async (e) => {
          const text = e.target.result;
          const json = {
            "Content": Buffer.from(text).toString("base64")
          };
          this.doUpload(json);
        };
        fr.onerror = (err) => showError(err);
        fr.readAsArrayBuffer(files[0]);
      } catch (ex) {
        showError(ex);
      }
    }
  }
  //upload du lieu code
  doUpload(json) {
    this.setState({ saving: true });
    server
      .post("ChiPhi/DoRequest", {
        Function: "Proc_DM_Codes_Import",
        ThamSo: json,
      })
      .then(() => {
        this.setState(
          {
            saving: false,
          },
          () => this.doSearch()
        );
      })
      .catch((error) => {
        this.setState({ saving: false });
        showError(error);
      });
  }

  doExport = () => {
    server
      .post("Chiphi/DoRequest", {
        "Function": "Proc_Table_GetAll",
        "ThamSo": {
          "TableNames": "DM_Codes"
        }
      })
      .then(response => {
        var json = JSON.stringify(response.DM_Codes);
        const blob = new Blob([json], { type: "application/json;charset=utf-8;" });
        myLib.downloadBlob(blob, "CODE EQUIP.json");
      })
      .catch(error => {
        handleServerError(error);
      });
  };

  //call khi user thay doi trang
  render() {
    //lay mot so thuoc tinh truyen vao tu form cha
    const { parentHeight } = this.props;
    return (
      <div>
        <Paper variant="outlined">
          <Grid container spacing={2} columns={24}>
            <Grid item xs={1}>
              <FormLabel>{fieldLabels.Code}</FormLabel>
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                value={this.state.after.Code}
                onChange={(e) => {
                  this.saveField("Code", e.target.value);
                }} />
            </Grid>
            <Grid item xs={2}>
              <FormLabel>{fieldLabels.CodeName}</FormLabel>
            </Grid>
            <Grid item xs={5}>
              <TextField
                fullWidth
                value={this.state.after.CodeName}
                onChange={(e) => {
                  this.saveField("CodeName", e.target.value);
                }} />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
              <ButtonTimKiem
                variant="contained"
                fullWidth
                title={buttonLabels.btnTimKiem}
                disabled={this.state.search_loading}
                onClick={this.doSearch}
              ></ButtonTimKiem>
            </Grid>
          </Grid>
        </Paper>
        <div style={{ height: 2 }}></div>
        <Grid container spacing={1} columns={24}>
          <Grid item xs={24}>
            <ReactDataGrid
              style={{
                height: parentHeight - 28 - 35,
                fontSize: 12,
              }}
              showZebraRows={true}
              i18n={englishUI ? undefined : ReactDataGrid_i18n}
              columns={this.columns_grid}
              pagination={true}
              multiSelect={false}
              showHoverRows={false}
              checkboxColumn={{
                render: (props) => (
                  <input
                    type="checkbox"
                    readOnly
                    checked={props.rowSelected}
                  ></input>
                ),
              }}
              defaultLimit={25}
              limit={this.state.after.PageSize}
              skip={
                (this.state.after.PageIndex - 1) * this.state.after.PageSize
              }
              onSkipChange={(skip) => {
                this.saveField(
                  "PageIndex",
                  skip / this.state.after.PageSize + 1
                );
              }}
              dataSource={this.state.search_dataSource}
              idProperty="Code"
              headerHeight={30}
              rowHeight={null}
              minRowHeight={30}
              allowUnsort={false}
              defaultSortInfo={defaultSortInfo}
              sortInfo={this.state.sortInfo}
              onSortInfoChange={this.onSortInfoChange}
              onLimitChange={(v) => this.saveField("PageSize", v)}
              emptyText={loadDataError(
                this.state.search_error,
                this.state.search_result.data.length === 0,
                this.state.errorMessage,
                this.state.errorLog
              )}
              skipLoadOnMount={true}
              enableSelection={true}
              onSelectionChange={({ data }) => {
                var now = new Date().getTime();
                var diff = now - this.lastClick;
                if (diff > 300) {
                  this.setState({ rowSelected: data });
                } else {
                  //double click
                  this.setState({
                    rowSelected: data,
                    rowDoubleClicked: data,
                  });
                }
                this.lastClick = now;
              }}
            ></ReactDataGrid>
          </Grid>
          <Grid item xs={24}>
            <Grid container spacing={2} columns={24}>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    var data = {
                      edittingRecord: undefined,
                      open_edit: true
                    };
                    this.setMyState(data);
                  }}
                >
                  {buttonLabels.btnThem}
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={!this.state.rowSelected}
                  onClick={() => {
                    let data = {
                      edittingRecord: { ...this.state.rowSelected },
                      open_edit: true
                    };
                    this.setMyState(data);
                  }}
                >
                  {buttonLabels.btnEdit}
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={!this.state.rowSelected}
                  onClick={() => {
                    this.setState({ showConfirmXoa: true });
                  }}
                >
                  {buttonLabels.btnDelete}
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Dropzone
                  maxFiles={1}
                  multiple={false}
                  accept={{ "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"] }}
                  onDrop={(files) => this.doImport(files)}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <Button
                        variant="contained"
                        fullWidth
                      >
                        {buttonLabels.btnImport}
                      </Button>
                    </div>
                  )}
                </Dropzone>

              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={this.doExport}
                >
                  {buttonLabels.btnExport}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {this.state.open_edit ? (
          <CodeEditDialog
            open={true}
            edittingRecord={this.state.edittingRecord}
            close={(saved) => {
              this.setState({ open_edit: false }, () => {
                if (saved) {
                  this.doSearch();
                }
              });
            }}
          />
        ) : null}
        {this.state.showConfirmXoa ? (
          <SimpleDialog
            confirm
            message={
              "Bạn muốn xóa: " + this.state.rowSelected.Code + " ?"
            }
            open={true}
            OKRunning={this.state.saving}
            close={(ok) => {
              console.log(this.state.rowSelected);
              if (!this.state.saving) {
                if (ok) {
                  //thuc hien xoa
                  this.setState({ saving: true });
                  server
                    .post("ChiPhi/DoRequest", {
                      Function: "Proc_DM_Codes_Delete",
                      ThamSo: {
                        Code: this.state.rowSelected.Code,
                        CodeName: this.state.rowSelected.CodeName,
                      },
                    })
                    .then(() => {
                      this.setState({ showConfirmXoa: false, saving: false });
                      //reload
                      this.doSearch();
                    })
                    .catch((error) => {
                      this.setState({ saving: false, showConfirmXoa: false });
                      handleServerError(error);
                    });
                } else {
                  this.setState({ showConfirmXoa: false });
                }
              }
            }}
          />
        ) : null}
      </div>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(CodePage);
