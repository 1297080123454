/* Khong hieu sao phai cho menu ra ngoai file rieng thi moi o duoi appbar */
import { DirectionsBoat, LegendToggleOutlined, TableView } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  menuText: {
    color: "#FFF",
  },
  menuIcon: {
    color: "#FFF",
  },
}));
const englishUI = true;
const menuTitles = englishUI
  ? {
    DanhMuc: "List",
    Codes: "Code",
    Ships: "Ship",
    Contractor: "Contractor",
    Budget: "Budget plan",
    FileTemplate: "File template"
  }
  : {
    DanhMuc: "Danh mục",
    Codes: "Mã code",
    Ships: "Tàu",
    Contractor: "Nhà thầu",
    Budget: "Kế hoạch chi phí năm",
    FileTemplate: "File mẫu"
  };
export default function MenuDanhMuc(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const hasGraph = global.hasRoles("R11");

  const hashShip = global.hasRoles("R12");
  const hasVoyage = global.hasRoles("R13");
  const hasCheckRule = global.hasRoles("R14");

  return (
    <>
      <IconButton
        id="basic-button"
        sx={{ marginLeft: "auto" }}
        size="large"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {open ? (
          <ArrowDropDownIcon className={classes.menuIcon} />
        ) : (
          <ArrowRightIcon className={classes.menuIcon} />
        )}
        <Typography className={classes.menuText} variant="h6" noWrap>
          {menuTitles.DanhMuc}
        </Typography>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {hasGraph ? (
          <>
            <MenuItem
              disabled={!hasGraph}
              onClick={() => {
                if (props.onClick) {
                  props.onClick({
                    name: "DM_Codes",
                    title: "",
                  });
                }
                handleClose();
              }}
            >
              <ListItemIcon>
                <DirectionsBoat fontSize="small" />
              </ListItemIcon>
              <ListItemText>{menuTitles.Codes}</ListItemText>
            </MenuItem>
            <Divider />
          </>
        ) : null}
        {hashShip ? (
          <>
            <MenuItem
              disabled={!hashShip}
              onClick={() => {
                if (props.onClick) {
                  props.onClick({
                    name: "DM_Ship",
                    title: "",
                  });
                }
                handleClose();
              }}
            >
              <ListItemIcon>
                <DirectionsBoat fontSize="small" />
              </ListItemIcon>
              <ListItemText>{menuTitles.Ships}</ListItemText>
            </MenuItem>
            <Divider />
          </>
        ) : null}
        {hasVoyage ? (
          <>
            <MenuItem
              disabled={!hasVoyage}
              onClick={() => {
                if (props.onClick) {
                  props.onClick({
                    name: "DM_CongTy",
                    title: "",
                  });
                }
                handleClose();
              }}
            >
              <ListItemIcon>
                <DirectionsBoat fontSize="small" />
              </ListItemIcon>
              <ListItemText>{menuTitles.Contractor}</ListItemText>
            </MenuItem>
            <Divider />
          </>
        ) : null}

        {hasCheckRule ? (
          <>
            <MenuItem
              onClick={() => {
                if (props.onClick) {
                  props.onClick({
                    name: "CP_KeHoachNam",
                    title: "",
                  });
                }
                handleClose();
              }}
            >
              <ListItemIcon>
                <LegendToggleOutlined fontSize="small" />
              </ListItemIcon>
              <ListItemText>{menuTitles.Budget}</ListItemText>
            </MenuItem>
            <Divider />
          </>
        ) : null}
        {hasCheckRule ? (
          <>
            <MenuItem
              onClick={() => {
                if (props.onClick) {
                  props.onClick({
                    name: "CP_FileTemplate",
                    title: "",
                  });
                }
                handleClose();
              }}
            >
              <ListItemIcon>
                <TableView fontSize="small" />
              </ListItemIcon>
              <ListItemText>{menuTitles.FileTemplate}</ListItemText>
            </MenuItem>
          </>
        ) : null}

      </Menu>
    </>
  );
}
