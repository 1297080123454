import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import {
  Article,
  TableView
} from '@mui/icons-material';
import {
  Button,
  Grid
} from "@mui/material";
import { withStyles } from "@mui/styles";
import moment from "moment";
import React from "react";
import "react-toastify/dist/ReactToastify.css";
/*----------------------Cac control trong project su dung den trong form----------------*/
import MenuButtons from "../../components/MenuButtons";
import SimpleDialog from "../../components/SimpleDialog";
import {
  ReactDataGrid_i18n,
  formStyles,
  handleServerError,
  loadDataError,
  showError
} from "../../components/common";
import PriceQuoteEditDialog from "./PriceQuoteEditDialog";
import PriceQuoteExportNewDialog from "./PriceQuoteExportNewDialog";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../../lib/MyLib");
const server = require("../../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const buttonExports = [
  {
    ID: "Word",
    Form: "TiengAnh",
    Label: "Word - English",
    LabelEN: "Word - English",
    Icon: <Article fontSize="small" />,
  },
  {
    ID: "Word",
    Form: "TiengViet",
    Label: "Word - Tiếng Việt",
    LabelEN: "Word - Tiếng Việt",
    Icon: <Article fontSize="small" />,
  },
  {
    ID: "Excel",
    Form: "TiengAnh",
    Label: "Excel - English",
    LabelEN: "Excel - English",
    Icon: <TableView fontSize="small" />,
  },
  {
    ID: "Excel",
    Form: "TiengViet",
    Label: "Excel - Tiếng Việt",
    LabelEN: "Excel - Tiếng Việt",
    Icon: <TableView fontSize="small" />,
  }
];
const buttonLabels = englishUI
  ? {
    btnTimKiem: "Search",
    btnExportNew: "File Templete",
    btnThem: "Add",
    btnEdit: "Edit",
    btnDelete: "Delete",
    btnExport: "Export",
    btnDetail: "Detail"
  }
  : {
    btnTimKiem: "Tìm kiếm",
    btnExportNew: "Mẫu YCCG",
    btnThem: "Thêm",
    btnEdit: "Sửa",
    btnDelete: "Xóa",
    btnExport: "Xuất file",
    btnDetail: "Chi tiết",
  };
/*-----------------------Dinh nghia cac column trong grid -----------------*/
const default_gridColumns = [
  {
    name: "SoYCCG",
    type: "string",
    defaultWidth: 100,
    headerAlign: "center",
    header: englishUI ? "No." : "Số YCCG",
    render: ({ data }) => data.SoYCCG
  },
  
  {
    name: "NgayYCCG",
    type: "string",
    defaultWidth: 100,
    headerAlign: "center",
    header: englishUI ? "Date" : "Ngày YCCG",
    render: ({ data }) => data.NgayYCCG ? moment(data.NgayYCCG).format("DD/MM/YYYY") : null
  },
  {
    name: "TenCongTy",
    type: "string",
    defaultWidth: 250,
    headerAlign: "center",
    header: englishUI ? "Contractor" : "Công ty",
    render: ({ data }) => data.TenCongTy
  },
  {
    name: "YeuCau",
    type: "string",
    flex: 1,
    headerAlign: "center",
    header: englishUI ? "Subject" : "Yêu cầu",
    render: ({ data }) => data.YeuCau
  },
  {
    name: "NguoiDeNghi",
    type: "string",
    defaultWidth: 120,
    headerAlign: "center",
    header: englishUI ? "Created by" : "Người đề nghị",
    render: ({ data }) => data.NguoiDeNghi
  },
  {
    name: "TruongPhong",
    type: "string",
    defaultWidth: 100,
    headerAlign: "center",
    header: englishUI ? "Approved by" : "Người duyệt",
    render: ({ data }) => data.TruongPhong
  },
  {
    name: "NgayCN",
    type: "date",
    headerAlign: "center",
    defaultWidth: 120,
    format: "HH:mm DD/MM/YYYY",
    header: englishUI ? "Updated time" : "Ngày CN",
    render: (props) =>
      props.data.NgayCN
        ? moment(props.data.NgayCN).format("HH:mm DD/MM/YYYY")
        : null,
  },
  {
    name: "MaYeuCauCG",
    type: "string",
    defaultWidth: 60,
    headerAlign: "center",
    header: "ID"
  }
];
/*-----------------------Cac bien su dung trong form ----------------------*/
const defaultSortInfo = { name: "MaYeuCau", dir: 0, type: "string" };
//tra ve index column tuy theo sort infor
const getSortColumnIndex = (columns, sortInfor) => {
  var find_index = 0;
  for (var i = 0; i < columns.length; i++) {
    var item = columns[i];
    if (item.name == sortInfor.name) {
      find_index = i;
      break;
    }
  }
  return find_index;
};

class PriceQuoteTab extends React.Component {
  lastClick = 0; //danh dau lan click truoc do' cua grid de xu ly double click
  constructor(props) {
    super(props);
    this.columns_grid = default_gridColumns;
    //load lai thong tin giao dien cua phien lam viec truoc do
    this.state = {
      dataChanged: false, //du lieu co su thay doi hay khong
      loadOnStart: true, //co load du lieu ngay khi mo form khong
      errorMessage: "", //mo ta loi neu co
      errorLog: "", //mo ta chi tiet loi
      DanhMuc: {
        DM_Complete: [{ value: 0, label: "No" }, { value: 1, label: "Yes" }]
      }, //luu cac du lieu danh muc su dung trong form
      //searching
      search_loading: false,
      search_result: { data: [], count: 0 }, //ket qua tim kiem
      search_dataSource: [],
      search_error: false, //search co loi khong
      lastSortInfo: defaultSortInfo, //vi grid co trang thai sortinfo = null nen phai co cai nay
      sortInfo: defaultSortInfo, //thong tin sap xep cua grid
      //chua cac tieu chi tim kiem
      after:
        props.value === undefined //gia tri truyen vao tu form cha
          ? {
            Ascending: true,
            SortBy: getSortColumnIndex(this.columns_grid, defaultSortInfo), //index column duoc sap xep, anh xa giua ten cot duoc chon va index cua colmn trong grid
          }
          : props.value, //thong tin tieu chi truyen vao tu form cha
    };
  }
  //ghi nhan 1 tieu chi tim kiem
  saveField = (fieldName, value) => {
    var s = this.state;
    if (fieldName !== "PageIndex") {
      s.after.PageIndex = 1;
    } else {
      if (s.after.PageIndex === value) {
        return; //ko co su thay doi thi bo qua
      }
    }
    s.after[fieldName] = value;
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    if (
      fieldName !== "PageSize" &&
      fieldName !== "PageIndex" &&
      fieldName !== "SortBy"
    ) {
      this.setMyState(this.state);
    } else {
      //lien quan den phan trang hoac sap xep thi reload
      this.setMyState(this.state, () => {
        this.doSearch();
      });
    }
  };
  saveData = (obj) => {
    var s = this.state;
    s = { ...s, after: { ...s.after, ...obj } };
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    this.setState(s);
  };
  //duoc su dung neu form hien thi trong che do MDI
  setMyState = (state, callback) => {
    this.setState(state, () => {
      //su dung cho muc dich MDI form
      if (this.props.onStateChanged) {
        this.props.onStateChanged(this.state);
      }
      //reload data
      if (callback) {
        callback();
      }
    });
  };
  //su kien form load
  componentDidMount() {
    this.doSearch();
  }
  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
  static getDerivedStateFromProps() {
    return null;
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentDidUpdate(prevProps) {
    //neu thong tin tau thay doi
    if (this.props.ShipData.ShipID !== prevProps.ShipData.ShipID) {
      //reset data
      this.state.rowSelected = undefined;
      this.setState({
        search_result: { data: [], count: 0 },
      });
      this.doSearch();
    }
  }
  //khi thay doi sap xep cot
  onSortInfoChange = (value) => {
    const newSort = value
      ? { type: value.type, name: value.name, dir: value.dir }
      : value;
    //ghi nhan vao sort by
    this.setState(
      {
        lastSortInfo: this.state.sortInfo
          ? this.state.sortInfo
          : this.state.lastSortInfo,
        sortInfo: newSort,
      },
      () => this.doSearch()
    );
  };
  loadData = ({ skip, limit }) => {
    var serverSortInfo = this.state.sortInfo
      ? this.state.sortInfo
      : this.state.lastSortInfo;

    var loader = new Promise((resolve) => {
      server
        .post("Chiphi/DoRequest", {
          Function: "Proc_CP_YeuCauCG_GetBy",
          ThamSo: {
            HangMucID: this.props.HangMucID
          },
        })
        .then((response) => {
          this.setState({
            search_loading: false,
            search_error: false,
            search_result: {
              data: response.CP_YeuCauCG,
              count: response.CP_YeuCauCG.Total,
            },
            rowSelected: undefined,
            rowDoubleClicked: undefined,
            ReportContent: "",
            Attachs: [],
          });
          resolve({
            data: response.CP_YeuCauCG,
            count: response.CP_YeuCauCG.Total,
          });
        })
        .catch((error) => {
          var message = "";
          var log = "";
          try {
            var obj = JSON.parse(error);
            message = obj.message;
            log = obj.traceLog;
          } catch (e) {
            message = error + "";
          }
          this.setState({
            search_loading: false,
            search_error: true,
            errorMessage: message,
            errorLog: log,
            search_result: { data: [], count: 0 },
            rowSelected: undefined,
          });
          resolve({ data: [], count: 0 });
        });
    });
    return loader;
  };
  //Thuc hien search
  doSearch = () => {
    var loader = this.loadData({
      skip: (this.state.after.PageIndex - 1) * this.state.after.PageSize,
      limit: this.state.after.PageSize,
      sortInfo: this.state.sortInfo,
    });
    this.setState({
      search_loading: true,
      search_dataSource: loader, //chi load sau khi co danh muc
      selectdReecord: {},
    });
  };

  edit() {
    server
      .post("ChiPhi/DoRequest", {
        Function: "Proc_CP_YeuCauCG_Get",
        ThamSo: {
          MaYeuCauCG: this.state.rowSelected.MaYeuCauCG,
        },
      })
      .then((response) => {
        let data = {
          edittingRecord: {
            ...response.CP_YeuCauCG[0]
          },
          open_edit: true
        };
        this.setMyState(data);
      })
      .catch((error) => {
        showError(error);
      });
  }

  doExport(exportFormat, language) {
    server
      .post("Chiphi/DoRequest", {
        Function: "Proc_CP_YeuCauCG_Export",
        ThamSo: {
          MaYeuCauCG: this.state.rowSelected.MaYeuCauCG,
          ExportFormat: exportFormat,
          Form: language,
          Authorization: server.getToken()
        },
      })
      .then((response) => {
        const decoded = Buffer.from(response.CP_YeuCauCG[0].ExcelData, "base64");
        const blob = new Blob([decoded]);
        myLib.downloadBlob(blob, exportFormat == "Excel" ? "CP_YeuCauCG.xlsx" : "CP_YeuCauCG.docx");
      })
      .catch((error) => {
        handleServerError(error);
        this.setState({ saving: false });
      });
  };

  render() {
    //lay mot so thuoc tinh truyen vao tu form cha
    const { parentHeight } = this.props;
    return (
      <>
        <Grid container spacing={1} columns={24}>
          <Grid item xs={24}>
            <ReactDataGrid
              style={{
                height: parentHeight - 28 - 35,
                fontSize: 12,
              }}
              showZebraRows={true}
              i18n={englishUI ? undefined : ReactDataGrid_i18n}
              columns={this.columns_grid}
              pagination={false}
              multiSelect={false}
              showHoverRows={false}
              checkboxColumn={{
                render: (props) => (
                  <input
                    type="checkbox"
                    readOnly
                    checked={props.rowSelected}
                  ></input>
                ),
              }}
              dataSource={this.state.search_dataSource}
              idProperty="MaYeuCauCG"
              rowHeight={30}
              allowUnsort={false}
              defaultSortInfo={defaultSortInfo}
              sortInfo={this.state.sortInfo}
              onSortInfoChange={this.onSortInfoChange}
              emptyText={loadDataError(
                this.state.search_error,
                this.state.search_result.data.length === 0,
                this.state.errorMessage,
                this.state.errorLog
              )}
              skipLoadOnMount={true}
              enableSelection={true}
              onSelectionChange={({ data }) => {
                var now = new Date().getTime();
                var diff = now - this.lastClick;
                if (diff > 300) {
                  this.setState({ rowSelected: data, ReportContent: "" });
                } else {
                  //double click
                  this.setState({
                    rowSelected: data,
                    rowDoubleClicked: data,
                  });
                }
                this.lastClick = now;
              }}
            ></ReactDataGrid>
          </Grid>
          <Grid item xs={24}>
            <Grid container spacing={2} columns={24}>
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    var data = {
                      open_export_new: true
                    };
                    this.setMyState(data);
                  }}
                >
                  {buttonLabels.btnExportNew}
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    var data = {
                      edittingRecord: undefined,
                      open_edit: true
                    };
                    this.setMyState(data);
                  }}
                >
                  {buttonLabels.btnThem}
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={!this.state.rowSelected}
                  onClick={() => {
                    this.edit();
                  }}
                >
                  {buttonLabels.btnEdit}
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={!this.state.rowSelected}
                  onClick={() => {
                    this.setState({ showConfirmXoa: true });
                  }}
                >
                  {buttonLabels.btnDelete}
                </Button>
              </Grid>
              <Grid item xs={2}>
                <MenuButtons
                  fullWidth
                  englishUI={englishUI}
                  title={buttonLabels.btnExport}
                  SubActions={buttonExports}
                  disabled={!this.state.rowSelected}
                  onClick={(subButton) => {
                    this.doExport(subButton.ID, subButton.Form);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {this.state.open_export_new &&
          <PriceQuoteExportNewDialog
            open={true}
            MaHangMuc={this.props.MaHangMuc}
            ShipData={this.props.ShipData}
            close={() => {
              this.setState({ open_export_new: false });
            }}
          />}
        {this.state.open_edit ? (
          <PriceQuoteEditDialog
            open={true}
            HangMucID={this.props.HangMucID}
            edittingRecord={this.state.edittingRecord}
            ShipData={this.props.ShipData}
            close={(saved) => {
              this.setState({ open_edit: false }, () => {
                if (saved) {
                  this.doSearch();
                }
              });
            }}
          />
        ) : null}
        {this.state.showConfirmXoa ? (
          <SimpleDialog
            confirm
            message={
              "Bạn muốn xóa: " + this.state.rowSelected.SoYCCG + " ?"
            }
            open={true}
            OKRunning={this.state.saving}
            close={(ok) => {
              console.log(this.state.rowSelected);
              if (!this.state.saving) {
                if (ok) {
                  //thuc hien xoa
                  this.setState({ saving: true });
                  server
                    .post("ChiPhi/DoRequest", {
                      Function: "Proc_CP_YeuCauCG_Delete",
                      ThamSo: {
                        MaYeuCauCG: this.state.rowSelected.MaYeuCauCG,
                      },
                    })
                    .then(() => {
                      this.setState({ showConfirmXoa: false, saving: false });
                      //reload
                      this.doSearch();
                    })
                    .catch((error) => {
                      this.setState({ saving: false, showConfirmXoa: false });
                      handleServerError(error);
                    });
                } else {
                  this.setState({ showConfirmXoa: false });
                }
              }
            }}
          />
        ) : null}
      </>
    )
  }
}

export default withStyles(formStyles, { withTheme: true })(PriceQuoteTab);