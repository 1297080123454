import "@inovua/reactdatagrid-enterprise/index.css";
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormLabel,
  Grid,
  Stack,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import Dropzone from "react-dropzone";
import "react-toastify/dist/ReactToastify.css";
/*----------------------Cac control trong project su dung den trong form----------------*/
import MyNumberEditor from "../components/MyNumberEditor";
import SimpleDialog from "../components/SimpleDialog";
import { formStyles, handleServerError, showError } from "../components/common";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI ? "Update evaluation" : "Cập nhật chấm điểm";
const buttonLabels = englishUI
  ? {
    btnSave: "Save",
    btnClose: "Close",
    btnImport: "Import Excel file"
  }
  : {
    btnSave: "Lưu",
    btnClose: "Thoát",
    btnImport: "Nhập từ file Excel"
  };

const fieldLabels_EN = {
  Nam: "Year:"
};
const fieldLabels_VN = {
  Nam: "Năm:"
};
const fieldLabels = englishUI ? fieldLabels_EN : fieldLabels_VN;
//ham filter su dung cho combobox
/*------------------------------------Mot so ham xu ly du lieu --------------------*/
function validate_Data(data, atts) {
  //validate danh sach truong khong duoc trong'
  var ret = myLib.validateFields(data, [
    "HangMucID",
    "MaCongTy",
    "SoPhieuGV",
    "NgayGV",
    "PhongYeuCau",
    "YeuCau",
    "NguoiDeNghi",
    "TruongPhong"
  ].reduce((obj, item) => Object.assign(obj, { [item]: { "allowEmpty": "false" } }), {}));

  ret.error = Object.keys(ret).length > 0;

  return ret;
}

/*------------------------------------FORM--------------------------------------*/
class ContractorEvaluationUpdateDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSaved: false, //du lieu da tung duoc save hay chua
      validateStatus: { error: false }, //trang thai validate
      after: {
        Nam: this.props.Nam,
        DanhSach: []
      },
      Attachs: []
    };
  }

  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps) {
    return { open: nextProps.open };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    server
      .post("ChiPhi/DoRequest", {
        Function: "Proc_CP_ChamDiemNam_Get",
        ThamSo: {
          Nam: this.state.after.Nam
        }
      })
      .then((response) => {
        this.saveField("DanhSach", response.CP_ChamDiemNam);
      })
      .catch((error) => {
        showError(error);
      });
  }

  saveField = (fieldName, value, callback) => {
    var s = this.state; //new value

    s.after[fieldName] = value;

    s.dataChanged = true;
    this.setState({ saving: false }, () => {
      if (callback) {
        callback();
      }
    });
  };

  validate_Data(data) {
    var ret = {};

    var ret = myLib.validateFields(data, [
      "Nam"
    ].reduce((obj, item) => Object.assign(obj, { [item]: { "allowEmpty": "false" } }), {}));

    ret.error = ret.error || data.DanhSach.length == 0;
    return ret;
  }

  doSave = () => {
    try {
      var check = this.validate_Data(this.state.after, this.state.Attachs);
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("ChiPhi/DoRequest", {
            Function: "Proc_CP_ChamDiemNam_Update",
            ThamSo: this.state.after.DanhSach.map(item => {
              return {
                ...item,
                Nam: this.state.after.Nam
              }
            })
          })
          .then(() => {
            this.props.close(true);
          })
          .catch((error) => {
            handleServerError(error);
            this.setState({ saving: false });
          });
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };

  //thuc hien import file
  doImport(files) {
    if (Array.isArray(files) && files.length == 1) {
      try {
        var fr = new FileReader();
        fr.onload = async (e) => {
          const text = e.target.result;
          const json = {
            "FileName": files[0].name,
            "Content": Buffer.from(text).toString("base64")
          };
          this.doUpload(json);
        };
        fr.onerror = (err) => showError(err);
        fr.readAsArrayBuffer(files[0]);
      } catch (ex) {
        showError(ex);
      }
    }
  }
  //upload du lieu report
  doUpload(json) {
    this.setState({ saving: true });
    server
      .post("ChiPhi/DoRequest", {
        Function: "Proc_CP_ChamDiemNam_ImportExcel",
        ThamSo: json
      })
      .then((response) => {
        if (Array.isArray(response.CP_ChamDiemNam)) {
          this.saveField("DanhSach", response.CP_ChamDiemNam);
        }
      })
      .catch((error) => {
        this.setState({ saving: false });
        showError(error);
      });
  }

  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close(this.state.dataSaved);
    }
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"xl"}
        fullWidth={true}
        onClose={() => { }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              {formTitle}
            </Typography>
            <Stack direction="row" spacing={4}>
              {this.state.loading_data ? (
                <CircularProgress color="warning" size={24} />
              ) : null}
              {this.state.saving ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}{this.state.loading ? (
                <CircularProgress color="primary" size={24} />
              ) : (<></>)}
              <Dropzone
                multiple={false}
                accept={{ "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"] }}
                onDrop={(acceptedFiles) => this.doImport(acceptedFiles)}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()} style={{ height: "100%" }}>
                      <input {...getInputProps()} />
                      <Button color="success" variant="contained" style={{ height: "100%" }}>
                        {buttonLabels.btnImport}
                      </Button>
                    </div>
                  </section>
                )}
              </Dropzone>
              <Button
                color="success"
                variant="contained"
                disabled={!this.state.dataChanged || this.state.saving}
                onClick={() => this.doSave()}
              >
                {buttonLabels.btnSave}
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={this.state.saving}
                onClick={this.handleClose}
              >
                {buttonLabels.btnClose}
              </Button>
            </Stack>
          </Stack>
        </AppBar>
        <DialogContent>
          <div
            style={{
              minWidth: 800,
              minHeight: 30,
              padding: 2,
              fontSize: 10,
              wordWrap: "break-word",
              overflow: "hidden",
            }}
          >
            <Grid container columns={24} rowSpacing={2} columnSpacing={1}>
              <Grid item xs={1}>
                <FormLabel>
                  {fieldLabels.Nam}
                </FormLabel>
              </Grid>
              <Grid item xs={1}>
                <MyNumberEditor
                  decimalScale={0}
                  value={this.state.after.Nam}
                  onChange={value => {
                    this.saveField("Nam", value);
                  }}
                  error={this.state.validateStatus.Nam !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container columns={24} rowSpacing={2} columnSpacing={1} style={{ marginTop: 10 }}>
              <Grid item xs={1}><b>No</b></Grid>
              <Grid item xs={9}><b>Shipyard/Sub-Contractor</b></Grid>
              <Grid item xs={2}><b>Accpt. Point</b></Grid>
              <Grid item xs={2}><b>Total Point</b></Grid>
              <Grid item xs={2}><b>Approved</b></Grid>
              <Grid item xs={8}><b>Remark</b></Grid>
              {this.state.after.DanhSach.map((item, index) => <>
                <Grid item xs={1}>{index + 1}.</Grid>
                <Grid item xs={9}>{item.TenCongTy}</Grid>
                <Grid item xs={2}>{item.AcceptPoint}</Grid>
                <Grid item xs={2}>{item.TotalPoint}</Grid>
                <Grid item xs={2}>{item.Approved ? "Yes" : "No"}</Grid>
                <Grid item xs={8}>{item.Remark}</Grid>
              </>)}
            </Grid>
          </div>
        </DialogContent>
        <SimpleDialog
          confirm
          message="Bạn chắc chắn muốn thoát ra ?"
          open={this.state.showConfirmExit}
          close={(ok) => {
            this.setState({ showConfirmExit: false });
            if (ok) {
              this.props.close(this.state.dataSaved);
            }
          }}
        />
      </Dialog>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(ContractorEvaluationUpdateDialog);
