import "@inovua/reactdatagrid-enterprise/index.css";
import {
  AppBar,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormLabel,
  Grid,
  IconButton,
  OutlinedInput,
  Stack,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/*----------------------Cac control trong project su dung den trong form----------------*/
import AddBoxIcon from "@mui/icons-material/AddBox";
import ClearIcon from "@mui/icons-material/Clear";
import MyDateEditor from "../components/MyDateEditor";
import MySelectFilter from "../components/MySelectFilter";
import SimpleDialog from "../components/SimpleDialog";
import {
  formStyles,
  handleServerError,
  showError
} from "../components/common";
import FieldNameDialog from "../pages/FieldNameDialog";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI ? "Repair Requisition" : "Yêu cầu sửa chữa";
const fieldLabels_EN = {
  ShipCode: "M/V:",
  BoPhan: "Department:",
  MaYeuCau: "Repair Requisition:",
  IsNormal: "Normal:",
  IsDocking: "Docking:",
  NgayYeuCau: "Date:",
  GhiChu: "Remark",
  GhiChu2: "Remark:",
  NguoiDeNghi: "Created by:",
  ThuyenTruong: "Approved by:",
};
const fieldLabels_VN = {
  ShipCode: "Tàu:",
  BoPhan: "Bộ phận:",
  MaYeuCau: "YCSC:",
  IsNormal: "Normal:",
  IsDocking: "Docking:",
  NgayYeuCau: "Ngày lập:",
  GhiChu: "Ghi chú",
  GhiChu2: "Ghi chú:",
  NguoiDeNghi: "Người đề nghị:",
  ThuyenTruong: "Người duyệt:",
};
const fieldLabels = englishUI ? fieldLabels_EN : fieldLabels_VN;
function getFieldNames() {
  var list = [];
  var keys = Object.keys(fieldLabels);
  for (var i = 0; i < keys.length; i++) {
    list.push({
      FieldName: keys[i],
    });
  }
  return list;
}
const listFields = getFieldNames();
const buttonLabels = englishUI
  ? {
      btnSave: "Save",
      btnClose: "Close",
      btnExport: "Export",
    }
  : {
      btnSave: "Lưu",
      btnClose: "Thoát",
      btnExport: "Xuất file",
    };

//ham filter su dung cho combobox
/*------------------------------------Mot so ham xu ly du lieu --------------------*/
function validate_Data(data) {
  //validate danh sach truong khong duoc trong'
  var ret = myLib.validateFields(data, {
    BoPhan: {
      allowEmpty: "false",
    },
    MaYeuCau: {
      allowEmpty: "false",
    },
    NgayYeuCau: {
      allowEmpty: "false",
    },
    NguoiDeNghi: {
      allowEmpty: "false",
    },
    ThuyenTruong: {
      allowEmpty: "false",
    },
  });

  ret.error = Object.keys(ret).length > 0;

  ret.NoiDung = data.NoiDung.map((job) => {
    return {
      NoiDung: myLib.isEmpty(job.Code) || myLib.isEmpty(job.NoiDung),
    };
  });

  ret.error = ret.error || ret.NoiDung.some((job) => job.NoiDung);

  return ret;
}

/*------------------------------------FORM--------------------------------------*/
class RepairRequisitionEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSaved: false, //du lieu da tung duoc save hay chua
      DanhMuc: { DM_Ship: [], DM_BoPhan: [], DM_Codes: [] },
      validateStatus: { error: false }, //trang thai validate
      after: {
        YeuCauID: null,
        FromShip: false,
        ShipCode: null,
        BoPhan: null,
        TenBoPhan: null,
        MaYeuCau: null,
        STT: null,
        IsNormal: true,
        IsDocking: false,
        NgayYeuCau: new Date(),
        Nam: new Date().getFullYear(),
        TrangThai: 0,
        GhiChu: null,
        NoiDung: [this.newRecord()],
        NguoiDeNghi: localStorage.getItem("RepairRequisition.NguoiDeNghi"),
        ThuyenTruong: localStorage.getItem("RepairRequisition.ThuyenTruong"),
        ...(props.edittingRecord ? props.edittingRecord : {}),
        ...(this.props.ShipData?.ShipID
          ? { ShipID: this.props.ShipData.ShipID }
          : {}),
        ...(this.props.ShipData?.ShipCode
          ? { ShipCode: this.props.ShipData.ShipCode }
          : {}),
      },
      showConfirmExit: false,
    };
  }

  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps) {
    return { open: nextProps.open };
  }

  componentDidMount() {
    this.loadDanhMuc("DM_Ship,DM_BoPhan,DM_Codes", () => {
      const tech = this.state.DanhMuc.DM_BoPhan.find(bp => bp.TenBoPhan == "TECH");
      if (tech) {
        this.saveData(tech, () => this.getMaYeuCau());
      }
    });
  }

  loadDanhMuc(danhsach, nextFunc) {
    //load danh muc
    if (danhsach) {
      server
        .post("ChiPhi/DoRequest", {
          Function: "Proc_Table_GetAll",
          ThamSo: {
            TableNames: danhsach,
          },
        })
        .then((response) => {
          this.setState(
            {
              DanhMuc: {
                ...this.state.DanhMuc,
                ...response,
              },
            },
            () => nextFunc()
          );
        })
        .catch((error) => {
          showError(error);
          nextFunc();
        });
    }
  }

  getMaYeuCau() {
    if (
      this.state.after.YeuCauID == null &&
      this.state.after.Nam &&
      this.state.after.BoPhan
    ) {
      server
        .post("ChiPhi/DoRequest", {
          Function: "Proc_CP_YeuCauSC_NextSTT",
          ThamSo: {
            ShipCode: this.state.after.ShipCode,
            Nam: this.state.after.Nam,
            BoPhan: this.state.after.BoPhan,
            IsDocking: this.state.after.IsDocking,
          },
        })
        .then((response) => {
          this.saveField("MaYeuCau", response.CP_YeuCauSC[0].Request);
          this.saveField("STT", response.CP_YeuCauSC[0].STT);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  saveField = (fieldName, value, callback) => {
    var s = this.state; //new value

    s.after[fieldName] = value;

    s.dataChanged = true;
    this.setState({ saving: false }, () => {
      if (callback) {
        callback();
      }
    });
  };
  saveData = (obj, callback) => {
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s, () => {
      if (callback) {
        callback();
      }
    });
  };
  saveRecord = (record, index, field, value, callback) => {
    record[field] = value;
    var s = { after: { ...this.state.after } }; //new value
    s.after.NoiDung[index] = record;
    s.dataChanged = true;
    this.setState(s, () => {
      if (callback) {
        callback();
      }
    });
  };

  newRecord(currentSize = 0) {
    return {
      STT: currentSize + 1,
      Code: null,
      CodeName: null,
      MucLuc: currentSize + 1,
      NoiDung: null,
      TrangThaiXL: 0,
      DVT: null,
      KhoiLuong: null
    };
  }

  addRecord() {
    var s = { after: { ...this.state.after } };
    s.after.NoiDung.push(this.newRecord(s.after.NoiDung.length));
    s.dataChanged = true;
    this.setState(s);
  }

  removeRecord(index) {
    var s = { after: { ...this.state.after } };
    s.after.NoiDung.splice(index, 1);
    s.after.NoiDung.forEach((job, index) => {
      return {
        ...job,
        STT: index + 1,
      };
    });
    s.dataChanged = true;

    this.setState(s);
  }

  doSave = () => {
    try {
      if (this.state.after.YeuCauID == null) {
        localStorage.setItem(
          "RepairRequisition.NguoiDeNghi",
          this.state.after.NguoiDeNghi
        );
        localStorage.setItem(
          "RepairRequisition.ThuyenTruong",
          this.state.after.ThuyenTruong
        );
      }
      var check = validate_Data(this.state.after);
      console.log(check, this.state.after);
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("ChiPhi/DoRequest", {
            Function: "Proc_CP_YeuCauSC_Update",
            ThamSo: {
              ...this.state.after,
              NoiDung: this.state.after.NoiDung.filter(
                (job) => !myLib.isEmpty(job.Code) && !myLib.isEmpty(job.NoiDung)
              ).map((job, index) => {
                return {
                  ...job,
                  STT: index + 1,
                };
              }),
            },
          })
          .then(() => {
            this.setState(
              {
                dataSaved: true,
                dataChanged: false,
                canSaved: false,
                saving: false,
                errorMessage: "",
                errorLog: "",
              },
              () => {
                this.props.close(this.state.dataSaved);
              }
            );
          })
          .catch((error) => {
            handleServerError(error);
            this.setState({ saving: false });
          });
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };

  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close(this.state.dataSaved);
    }
  };

  render() {
    const { classes, theme } = this.props;
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"xl"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              {formTitle}
            </Typography>
            <Stack direction="row" spacing={4}>
              {this.state.loading_data ? (
                <CircularProgress color="warning" size={24} />
              ) : null}
              {this.state.saving ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}
              {this.state.loading ? (
                <CircularProgress color="primary" size={24} />
              ) : (
                <></>
              )}
              <Button
                color="success"
                variant="contained"
                disabled={!this.state.dataChanged || this.state.saving}
                onClick={() => this.doSave()}
              >
                {buttonLabels.btnSave}
              </Button>

              <Button
                color="success"
                variant="contained"
                disabled={this.state.saving}
                onClick={this.handleClose}
              >
                {buttonLabels.btnClose}
              </Button>
            </Stack>
          </Stack>
        </AppBar>
        <DialogContent>
          <div
            style={{
              minWidth: 800,
              minHeight: 30,
              padding: 2,
              fontSize: 10,
              wordWrap: "break-word",
              overflow: "hidden",
            }}
          >
            <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "ShipCode",
                    })
                  }
                >
                  {fieldLabels.ShipCode}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="ShipCode"
                  key="ShipCode"
                  fullWidth
                  readOnly
                  value={this.state.after.ShipCode}
                  onChange={(event) => {
                    this.saveField("ShipCode", event.target.value);
                  }}
                  error={this.state.validateStatus.ShipCode !== undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "BoPhan",
                    })
                  }
                >
                  {fieldLabels.BoPhan}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                {this.state.after.FromShip ? (
                  <OutlinedInput
                    id="BoPhan"
                    key="BoPhan"
                    fullWidth
                    readOnly
                    value={this.state.after.TenBoPhan}
                  />
                ) : (
                  <MySelectFilter
                    options={this.state.DanhMuc.DM_BoPhan}
                    optionLabel="TenBoPhan"
                    optionValue="BoPhan"
                    readOnly
                    value={this.state.after}
                    onChange={(item) => {
                      this.saveData(item, () => this.getMaYeuCau());
                    }}
                    error={this.state.validateStatus.BoPhan !== undefined}
                  />
                )}
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "MaYeuCau",
                    })
                  }
                >
                  {fieldLabels.MaYeuCau}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="MaYeuCau"
                  key="MaYeuCau"
                  fullWidth
                  placeholder=""
                  value={this.state.after.MaYeuCau}
                  onChange={(event) => {
                    this.saveField("MaYeuCau", event.target.value);
                  }}
                  error={this.state.validateStatus.MaYeuCau !== undefined}
                />
              </Grid>
            </Grid>

            <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
              <Grid item xs={24}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "IsNormal",
                    })
                  }
                >
                  {fieldLabels.IsNormal}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <Checkbox
                  id="IsNormal"
                  key="IsNormal"
                  sx={{ padding: 0 }}
                  checked={this.state.after.IsNormal}
                  onChange={(event) => {
                    this.saveField("IsNormal", event.target.checked);
                    this.saveField("IsDocking", !event.target.checked, () =>
                      this.getMaYeuCau()
                    );
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "IsDocking",
                    })
                  }
                >
                  {fieldLabels.IsDocking}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <Checkbox
                  id="IsDocking"
                  key="IsDocking"
                  sx={{ padding: 0 }}
                  checked={this.state.after.IsDocking}
                  onChange={(event) => {
                    this.saveField("IsDocking", event.target.checked, () =>
                      this.getMaYeuCau()
                    );
                    this.saveField("IsNormal", !event.target.checked);
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "NgayYeuCau",
                    })
                  }
                >
                  {fieldLabels.NgayYeuCau}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateEditor
                  id="NgayYeuCau"
                  key="NgayYeuCau"
                  fullWidth
                  value={this.state.after.NgayYeuCau}
                  onChange={(value) => {
                    this.saveField("NgayYeuCau", value);
                    this.saveField("Nam", new Date(value).getFullYear(), () =>
                      this.getMaYeuCau()
                    );
                  }}
                  error={this.state.validateStatus.NgayYeuCau !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.groupHeader} align="center">
                  Repair Jobs
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <hr />
              </Grid>
              <Grid item xs={1}>
                <FormLabel><b>TT</b></FormLabel>
              </Grid>
              <Grid item xs={5}>
                <FormLabel><b>Code</b></FormLabel>
              </Grid>
              <Grid item xs={1}>
                <FormLabel><b>No.</b></FormLabel>
              </Grid>
              <Grid item xs={12}>
                <FormLabel><b>Description</b></FormLabel>
              </Grid>
              <Grid item xs={2}>
                <FormLabel><b>Unit</b></FormLabel>
              </Grid>
              <Grid item xs={2}>
                <FormLabel><b>Quantity</b></FormLabel>
              </Grid>
              <Grid item xs={1}> </Grid>
              {this.state.after.NoiDung.map((item, index) => (
                <>
                  <Grid item xs={1}>
                    <FormLabel>{index + 1}.</FormLabel>
                  </Grid>
                  <Grid item xs={5}>
                    <MySelectFilter
                      fullWidth
                      options={this.state.DanhMuc.DM_Codes.map((item) => {
                        return {
                          Code: item.Code,
                          CodeName: `${item.Code} - ${item.CodeName}`,
                        };
                      })}
                      optionValue="Code"
                      optionLabel="CodeName"
                      placeholder="Select code"
                      value={item}
                      onChange={(temp, selectedItem) => {
                        if (selectedItem) {
                          this.saveRecord(
                            item,
                            index,
                            "Code",
                            selectedItem.Code
                          );
                          this.saveRecord(
                            item,
                            index,
                            "CodeName",
                            selectedItem.CodeName
                          );
                        }
                      }}
                      error={
                        Array.isArray(this.state.validateStatus.NoiDung) &&
                        this.state.validateStatus.NoiDung[index]?.NoiDung == true
                      }
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <OutlinedInput
                      fullWidth
                      placeholder="No."
                      value={item.MucLuc}
                      onChange={(event) => {
                        this.saveRecord(item, index, "MucLuc", event.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <OutlinedInput
                      fullWidth
                      placeholder="Description"
                      value={item.NoiDung}
                      multiline
                      onChange={(event) => {
                        this.saveRecord(
                          item,
                          index,
                          "NoiDung",
                          event.target.value
                        );
                      }}
                      error={
                        Array.isArray(this.state.validateStatus.NoiDung) &&
                        this.state.validateStatus.NoiDung[index]?.NoiDung ==
                        true
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <OutlinedInput
                      fullWidth
                      value={item.DVT}
                      onChange={(event) => {
                        this.saveRecord(item, index, "DVT", event.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <OutlinedInput
                      fullWidth
                      value={item.KhoiLuong}
                      onChange={(event) => {
                        this.saveRecord(item, index, "KhoiLuong", event.target.value);
                      }}
                    />
                  </Grid>
                  {index == 0 ? (
                    <Grid item xs={1}>
                      <IconButton
                        size="small"
                        onClick={() => {
                          this.addRecord();
                        }}
                      >
                        <AddBoxIcon color="primary" size={24} />
                      </IconButton>
                    </Grid>
                  ) : (
                    <>
                      <Grid item xs={1}>
                        <IconButton
                          size="small"
                          fullWidth
                          onClick={() => {
                            this.removeRecord(index);
                          }}
                        >
                          <ClearIcon color="warning" size={24} />
                        </IconButton>
                      </Grid>
                    </>
                  )}
                </>
              ))}
            </Grid>
            <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
              <Grid item xs={24}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.GhiChu2}</FormLabel>
              </Grid>
              <Grid item xs={22}>
                <OutlinedInput
                  id="GhiChu"
                  key="GhiChu"
                  fullWidth
                  multiline
                  minRows={3}
                  value={this.state.after.GhiChu2}
                  onChange={(event) => {
                    this.saveField("GhiChu", event.target.value);
                  }}
                  error={this.state.validateStatus.GhiChu !== undefined}
                />
              </Grid>
            </Grid>
            <Grid container columns={24} rowSpacing={0} columnSpacing={1}>
              <Grid item xs={24}>
                <hr />
              </Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.NguoiDeNghi}</FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="NguoiDeNghi"
                  key="NguoiDeNghi"
                  fullWidth
                  value={this.state.after.NguoiDeNghi}
                  onChange={(event) => {
                    this.saveField("NguoiDeNghi", event.target.value);
                  }}
                  error={this.state.validateStatus.NguoiDeNghi !== undefined}
                />
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "ThuyenTruong",
                    })
                  }
                >
                  {fieldLabels.ThuyenTruong}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <OutlinedInput
                  id="ThuyenTruong"
                  key="ThuyenTruong"
                  fullWidth
                  value={this.state.after.ThuyenTruong}
                  onChange={(event) => {
                    this.saveField("ThuyenTruong", event.target.value);
                  }}
                  error={this.state.validateStatus.ThuyenTruong !== undefined}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        <SimpleDialog
          confirm
          message="Bạn chắc chắn muốn thoát ra ?"
          open={this.state.showConfirmExit}
          close={(ok) => {
            this.setState({ showConfirmExit: false });
            if (ok) {
              this.props.close(this.state.dataSaved);
            }
          }}
        />
        {this.state.show_thongtin_field ? (
          <FieldNameDialog
            open={true}
            FormName="RepairRequisitionEditDialog"
            ListFields={listFields}
            FieldName={this.state.Show_FieldName}
            close={() => {
              this.setState({ show_thongtin_field: false });
            }}
          />
        ) : null}
      </Dialog>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(
  RepairRequisitionEditDialog
);
