import {
  FormLabel,
  Grid,
  Paper
} from "@mui/material";
import moment from "moment";
import React from "react";
import {
  ReactDataGrid_i18n,
  formStyles,
  loadDataError,
  showError
} from "../components/common";

import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import { withStyles } from "@mui/styles";
import "react-toastify/dist/ReactToastify.css";
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import LockIcon from '@mui/icons-material/Lock';
import BlockIcon from '@mui/icons-material/Block';
/*----------------------Cac control trong project su dung den trong form----------------*/
import ButtonTimKiem from "../components/ButtonTimKiem";
import MenuButtons from "../components/MenuButtons";
import MyDateEditor from "../components/MyDateEditor";
import MySelectFilter from "../components/MySelectFilter";
/*-----------------------Cac thu vien dung chung -------------------------*/
const server = require("../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/

const fieldLabels = englishUI
  ? {
    NgayLap1: "From:",
    NgayLap2: "To:",
    IsKetThuc: "Completed:"
  }
  : {
    NgayLap1: "Từ:",
    NgayLap2: "Đến:",
    IsKetThuc: "Đã kết thúc:"
  };

const buttonLabels = englishUI
  ? {
    btnTimKiem: "Search",
    btnUpdateTrangThai: "Update status"
  }
  : {
    btnTimKiem: "Tìm kiếm",
    btnUpdateTrangThai: "Cập nhật trạng thái XL"
  };
const buttonUpdateStatuses = [
  {
    ID: 0,
    Label: "Chưa xử lý",
    LabelEN: "Pending",
    Icon: <PrecisionManufacturingIcon />
  },
  {
    ID: 2,
    Label: "Đã hoàn thành",
    LabelEN: "Completed",
    Icon: <DoneAllIcon color="primary" />
  },
  {
    ID: 3,
    Label: "Đã hủy",
    LabelEN: "Cancelled",
    Icon: <BlockIcon color="error" />
  }
];
/*-----------------------Cac bien su dung trong form ----------------------*/
const defaultSortInfo = { name: "NgayYeuCau", dir: 0, type: "string" };
//tra ve index column tuy theo sort infor
const getSortColumnIndex = (columns, sortInfor) => {
  var find_index = 0;
  for (var i = 0; i < columns.length; i++) {
    var item = columns[i];
    if (item.name == sortInfor.name) {
      find_index = i;
      break;
    }
  }
  return find_index;
};

//ham filter su dung cho combobox

class JobPage extends React.Component {
  selectionCounter = 0; //dem so lan click chon row trong grid
  lastClick = 0; //danh dau lan click truoc do' cua grid de xu ly double click
  constructor(props) {
    super(props);
    this.columns_grid = [
      {
        name: "Code",
        type: "string",
        defaultWidth: 100,
        headerAlign: "center",
        header: englishUI ? "Code" : "Code",
        render: ({ data }) => data.Code
      },
      {
        name: "NoiDung",
        type: "string",
        defaultWidth: 200,
        headerAlign: "center",
        header: englishUI ? "Description" : "Hạng mục công việc",
        render: ({ data }) => <div style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{data.NoiDung}</div>
      },
      {
        name: "MaYeuCau",
        type: "string",
        defaultWidth: 180,
        headerAlign: "center",
        header: englishUI ? "Repair Requisition" : "Số YCSC",
        render: ({ data }) => data.MaYeuCau
      },
      {
        name: "MaHangMuc",
        type: "string",
        defaultWidth: 150,
        headerAlign: "center",
        header: englishUI ? "ReS No." : "Số HMSC",
        render: ({ data }) => data.MaHangMuc
      },
      {
        name: "SoPhieuGV",
        type: "string",
        defaultWidth: 150,
        headerAlign: "center",
        header: englishUI ? "PO No." : "Số phiếu GV",
        render: ({ data }) => data.SoPhieuGV
      },
      {
        name: "TenTrangThai",
        type: "string",
        defaultWidth: 100,
        headerAlign: "center",
        header: englishUI ? "Status" : "Tình trạng",
        render: (props) => <MenuButtons
          fullWidth
          variant="outlined"
          englishUI={englishUI}
          title={props.value}
          SubActions={props.data.NgayQuaHan == null ? buttonUpdateStatuses.filter(s => s.ID != 2) : buttonUpdateStatuses}
          onClick={(subButton) => {
            this.doUpdateStatus(props.data.MaNoiDung, subButton.ID);
          }}
        />
      },
      {
        name: "NgayYeuCau",
        type: "date",
        headerAlign: "center",
        defaultWidth: 120,
        header: englishUI ? "Created date" : "Ngày yêu cầu",
        render: ({ data }) => data.NgayYeuCau ? moment(data.NgayYeuCau).format("DD/MM/YYYY") : null,
      },
      {
        name: "NgayQuaHan",
        type: "date",
        headerAlign: "center",
        defaultWidth: 200,
        header: englishUI ? "Due date" : "Ngày quá hạn",
        render: ({ data }) => <MyDateEditor
          fullWidth
          value={data.NgayQuaHan}
          onChange={(value) => {
            this.doUpdateHetHan(data.MaNoiDung, value);
          }}
        />,
      },
      {
        name: "MaNoiDung",
        type: "string",
        defaultWidth: 60,
        headerAlign: "center",
        header: "ID"
      }
    ];
    //load lai thong tin giao dien cua phien lam viec truoc do
    this.state = {
      dataChanged: false, //du lieu co su thay doi hay khong
      loadOnStart: true, //co load du lieu ngay khi mo form khong
      errorMessage: "", //mo ta loi neu co
      errorLog: "", //mo ta chi tiet loi
      DanhMuc: {
        DM_Complete: [{ value: 0, label: "No" }, { value: 1, label: "Yes" }]
      }, //luu cac du lieu danh muc su dung trong form
      //searching
      search_loading: false,
      search_result: { data: [], count: 0 }, //ket qua tim kiem
      search_dataSource: [],
      search_error: false, //search co loi khong
      lastSortInfo: defaultSortInfo, //vi grid co trang thai sortinfo = null nen phai co cai nay
      sortInfo: defaultSortInfo, //thong tin sap xep cua grid
      //chua cac tieu chi tim kiem
      after:
        props.value === undefined //gia tri truyen vao tu form cha
          ? {
            PageIndex: 1,
            PageSize: 25,
            Ascending: true,
            SortBy: getSortColumnIndex(this.columns_grid, defaultSortInfo), //index column duoc sap xep, anh xa giua ten cot duoc chon va index cua colmn trong grid
            //danh sach cac tieu chi tim kiem
            NgayLap1: null,
            NgayLap2: null,
            IsKetThuc: 0
          }
          : props.value, //thong tin tieu chi truyen vao tu form cha
    };
  }
  //ghi nhan 1 tieu chi tim kiem
  saveField = (fieldName, value) => {
    var s = this.state;
    if (fieldName !== "PageIndex") {
      s.after.PageIndex = 1;
    } else {
      if (s.after.PageIndex === value) {
        return; //ko co su thay doi thi bo qua
      }
    }
    s.after[fieldName] = value;
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    if (
      fieldName !== "PageSize" &&
      fieldName !== "PageIndex" &&
      fieldName !== "SortBy" &&
      fieldName !== "IsKetThuc"
    ) {
      this.setMyState(this.state);
    } else {
      //lien quan den phan trang hoac sap xep thi reload
      this.setMyState(this.state, () => {
        this.doSearch();
      });
    }
  };
  saveData = (obj) => {
    var s = this.state;
    s = { ...s, after: { ...s.after, ...obj } };
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    this.setState(s);
  };
  //duoc su dung neu form hien thi trong che do MDI
  setMyState = (state, callback) => {
    this.setState(state, () => {
      //su dung cho muc dich MDI form
      if (this.props.onStateChanged) {
        this.props.onStateChanged(this.state);
      }
      //reload data
      if (callback) {
        callback();
      }
    });
  };
  //su kien form load
  componentDidMount() {
    this.doSearch();
  }
  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
  static getDerivedStateFromProps() {
    return null;
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentDidUpdate(prevProps) {
    //neu thong tin tau thay doi
    if (this.props.ShipData.ShipID !== prevProps.ShipData.ShipID) {
      //reset data
      this.state.rowSelected = undefined;
      this.setState({
        search_result: { data: [], count: 0 },
      });
      this.doSearch();
    }
  }
  //khi thay doi sap xep cot
  onSortInfoChange = (value) => {
    const newSort = value
      ? { type: value.type, name: value.name, dir: value.dir }
      : value;
    //ghi nhan vao sort by
    this.setState(
      {
        lastSortInfo: this.state.sortInfo
          ? this.state.sortInfo
          : this.state.lastSortInfo,
        sortInfo: newSort,
      },
      () => this.doSearch()
    );
  };
  loadData = ({ skip, limit }) => {
    var serverSortInfo = this.state.sortInfo
      ? this.state.sortInfo
      : this.state.lastSortInfo;

    var loader = new Promise((resolve) => {
      server
        .post("Chiphi/DoRequest", {
          Function: "Proc_CP_YeuCauSC_ND_Search",
          ThamSo: {
            ...this.state.after,
            ShipID: this.props.ShipData.ShipID,
            PageIndex: skip / limit + 1,
            PageSize: limit,
            SortBy: getSortColumnIndex(this.columns_grid, serverSortInfo),
            Ascending: serverSortInfo.dir == 1,
          },
        })
        .then((response) => {
          this.setState({
            search_loading: false,
            search_error: false,
            search_result: {
              data: response.DataList,
              count: response.DataCount[0].Total,
            },
            rowSelected: undefined,
            rowDoubleClicked: undefined
          });
          resolve({
            data: response.DataList,
            count: response.DataCount[0].Total,
          });
        })
        .catch((error) => {
          var message = "";
          var log = "";
          try {
            var obj = JSON.parse(error);
            message = obj.message;
            log = obj.traceLog;
          } catch (e) {
            message = error + "";
          }
          this.setState({
            search_loading: false,
            search_error: true,
            errorMessage: message,
            errorLog: log,
            search_result: { data: [], count: 0 },
            rowSelected: undefined,
          });
          resolve({ data: [], count: 0 });
        });
    });
    return loader;
  };
  //Thuc hien search
  doSearch = () => {
    var loader = this.loadData({
      skip: (this.state.after.PageIndex - 1) * this.state.after.PageSize,
      limit: this.state.after.PageSize,
      sortInfo: this.state.sortInfo,
    });
    this.setState({
      search_loading: true,
      search_dataSource: loader, //chi load sau khi co danh muc
      selectdReecord: {},
    });
  };

  doUpdateStatus(MaNoiDung, TrangThaiXL) {
    server
      .post("ChiPhi/DoRequest", {
        Function: "Proc_CP_YeuCauSC_ND_Update",
        ThamSo: [
          {
            "MaNoiDung": MaNoiDung,
            "TrangThaiXL": TrangThaiXL
          }
        ],
      })
      .then(() => {
        this.doSearch();
      })
      .catch((error) => {
        showError(error);
      });
  }

  doUpdateHetHan(MaNoiDung, NgayQuaHan) {
    server
      .post("ChiPhi/DoRequest", {
        Function: "Proc_CP_YeuCauSC_ND_UpdateHetHan",
        ThamSo: [
          {
            "MaNoiDung": MaNoiDung,
            "NgayQuaHan": NgayQuaHan
          }
        ],
      })
      .then(() => {
        this.doSearch();
      })
      .catch((error) => {
        showError(error);
      });
  }

  //call khi user thay doi trang
  render() {
    //lay mot so thuoc tinh truyen vao tu form cha
    const { parentHeight } = this.props;
    return (
      <div>
        <Paper variant="outlined">
          <Grid container spacing={2} columns={24}>
            <Grid item xs={2}>
              <FormLabel>{fieldLabels.NgayLap1}</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <MyDateEditor
                id="NgayLap1"
                key="NgayLap1"
                fullWidth
                value={this.state.after.NgayLap1}
                onChange={(value) => {
                  this.saveField("NgayLap1", value);
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <FormLabel>{fieldLabels.NgayLap2}</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <MyDateEditor
                id="NgayLap2"
                key="NgayLap2"
                fullWidth
                value={this.state.after.NgayLap2}
                onChange={(value) => {
                  this.saveField("NgayLap2", value);
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <FormLabel>{fieldLabels.IsKetThuc}</FormLabel>
            </Grid>
            <Grid item xs={3}>
              <MySelectFilter
                id={"IsKetThuc"}
                key={"IsKetThuc"}
                fullWidth
                autoFocus={false}
                options={this.state.DanhMuc.DM_Complete}
                optionLabel="label"
                optionValue="value"
                value={this.state.DanhMuc.DM_Complete.find(c => c.value == this.state.after.IsKetThuc)}
                onChange={(item) => {
                  this.saveField("IsKetThuc", item.value);
                }}
              ></MySelectFilter>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={3}>
              <ButtonTimKiem
                variant="contained"
                fullWidth
                title={buttonLabels.btnTimKiem}
                disabled={this.state.search_loading}
                onClick={this.doSearch}
              ></ButtonTimKiem>
            </Grid>
          </Grid>
        </Paper>
        <div style={{ height: 2 }}></div>
        <Grid container spacing={1} columns={24}>
          <Grid item xs={24}>
            <ReactDataGrid
              style={{
                height: parentHeight - 28,
                fontSize: 12,
              }}
              showZebraRows={true}
              i18n={englishUI ? undefined : ReactDataGrid_i18n}
              rowStyle={({ data }) => {
                return data.TrangThaiXL == 0 && data.NgayQuaHan != null && moment(data.NgayQuaHan) < moment() ? {
                  backgroundColor: '#ef9a9a'
                } : {};
              }}
              columns={this.columns_grid}
              pagination={true}
              multiSelect={false}
              showHoverRows={false}
              defaultLimit={25}
              limit={this.state.after.PageSize}
              skip={
                (this.state.after.PageIndex - 1) * this.state.after.PageSize
              }
              onSkipChange={(skip) => {
                this.saveField(
                  "PageIndex",
                  skip / this.state.after.PageSize + 1
                );
              }}
              dataSource={this.state.search_dataSource}
              idProperty="MaNoiDung"
              rowHeight={null}
              minRowHeight={30}
              allowUnsort={false}
              defaultSortInfo={defaultSortInfo}
              sortInfo={this.state.sortInfo}
              onSortInfoChange={this.onSortInfoChange}
              onLimitChange={(v) => this.saveField("PageSize", v)}
              emptyText={loadDataError(
                this.state.search_error,
                this.state.search_result.data.length === 0,
                this.state.errorMessage,
                this.state.errorLog
              )}
              skipLoadOnMount={true}
            ></ReactDataGrid>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(JobPage);
