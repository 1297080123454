import "@inovua/reactdatagrid-enterprise/index.css";
import {
  Button,
  FormLabel,
  Grid,
  Link,
  OutlinedInput,
  Stack
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/*----------------------Cac control trong project su dung den trong form----------------*/
import MyDateEditor from "../../components/MyDateEditor";
import MyNumberEditor from "../../components/MyNumberEditor";
import SimpleDialog from "../../components/SimpleDialog";
import { formStyles, handleServerError, showError } from "../../components/common";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../../lib/MyLib");
const server = require("../../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
const buttonLabels = englishUI
  ? {
    btnSave: "Save",
    btnDelete: "Delete",
  }
  : {
    btnSave: "Lưu",
    btnDelete: "Xóa"
  };

const fieldLabels_EN = {
  HoaDon_So: "Invoice No:",
  HoaDon_Ngay: "Date:",
  HoaDon_TruocThue: "Price before tax:",
  HoaDon_SauThue: "Price after tax:"
};
const fieldLabels_VN = {
  HoaDon_So: "Số hóa đơn:",
  HoaDon_Ngay: "Ngày xuất hóa đơn:",
  HoaDon_TruocThue: "Giá thành trước thuế:",
  HoaDon_SauThue: "Giá thành sau thuế:"
};
const fieldLabels = englishUI ? fieldLabels_EN : fieldLabels_VN;

class InvoiceTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSaved: false, //du lieu da tung duoc save hay chua
      DanhMuc: { DM_CongTy: [] },
      validateStatus: { error: false }, //trang thai validate
      after: {
        HangMucID: this.props.HangMucID,
        HoaDon_So: null,
        HoaDon_Ngay: null,
        HoaDon_TruocThue: null,
        HoaDon_SauThue: null
      }
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    server
      .post("ChiPhi/DoRequest", {
        Function: "Proc_CP_HangMucSC_Get",
        ThamSo: {
          HangMucID: this.props.HangMucID,
        },
      }).then((response) => {
        let s = this.state;
        s = {
          ...s,
          PhieuGV: Array.isArray(response.CP_PhieuGV) && response.CP_PhieuGV.length > 0 ? response.CP_PhieuGV[0] : {}
        };

        s.after = {
          ...s.after,
          HoaDon_So: s.PhieuGV.HoaDon_So ? s.PhieuGV.HoaDon_So : "",
          HoaDon_Ngay: s.PhieuGV.HoaDon_Ngay ? s.PhieuGV.HoaDon_Ngay : null,
          HoaDon_TruocThue: s.PhieuGV.HoaDon_TruocThue ? s.PhieuGV.HoaDon_TruocThue : "",
          HoaDon_SauThue: s.PhieuGV.HoaDon_SauThue ? s.PhieuGV.HoaDon_SauThue : ""
        };

        this.setState(s);
      }).catch((error) => {
        showError(error);
      });
  }

  saveField = (fieldName, value) => {
    var s = this.state; //new value

    s.after[fieldName] = value;

    s.dataChanged = true;
    s.saving = false;
    this.setState(s);
  };

  validate_Data(data, atts) {
    //validate danh sach truong khong duoc trong'
    var ret = myLib.validateFields(data, [
      "HoaDon_So",
      "HoaDon_Ngay",
      "HoaDon_TruocThue",
      "HoaDon_SauThue"
    ].reduce((obj, item) => Object.assign(obj, { [item]: { "allowEmpty": "false" } }), {}));

    ret.error = Object.keys(ret).length > 0;

    return ret;
  }

  doSave = () => {
    try {
      var check = this.validate_Data(this.state.after, this.state.Attachs);
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("ChiPhi/DoRequest", {
            Function: "Proc_CP_PhieuGV_HoaDon",
            ThamSo: {
              ...this.state.after
            },
          })
          .then(() => {
            this.loadData();
            toast.success("Saved data successfully.")
          })
          .catch((error) => {
            handleServerError(error);
          })
          .finally(() => {
            this.setState({ saving: false });
          });
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };

  render() {
    return <>
      <Stack spacing={20}>
        <Grid container columns={24} rowSpacing={2} columnSpacing={1}>
          <Grid item xs={2}>
            <FormLabel
              onClick={() =>
                this.setState({
                  show_thongtin_field: true,
                  Show_FieldName: "HoaDon_So",
                })
              }
            >
              {fieldLabels.HoaDon_So}
            </FormLabel>
          </Grid>
          <Grid item xs={4}>
            <OutlinedInput
              fullWidth
              value={this.state.after.HoaDon_So}
              onChange={(event) => {
                this.saveField("HoaDon_So", event.target.value);
              }}
              error={this.state.validateStatus.HoaDon_So !== undefined}
            />
          </Grid>
          <Grid item xs={2}>
            <FormLabel
              onClick={() =>
                this.setState({
                  show_thongtin_field: true,
                  Show_FieldName: "HoaDon_Ngay",
                })
              }
            >
              {fieldLabels.HoaDon_Ngay}
            </FormLabel>
          </Grid>
          <Grid item xs={4}>
            <MyDateEditor
              fullWidth
              value={this.state.after.HoaDon_Ngay}
              onChange={(value) => {
                this.saveField("HoaDon_Ngay", value);
              }}
              error={this.state.validateStatus.HoaDon_Ngay !== undefined}
            />
          </Grid>
          <Grid item xs={2}>
            <FormLabel
              onClick={() =>
                this.setState({
                  show_thongtin_field: true,
                  Show_FieldName: "HoaDon_TruocThue",
                })
              }
            >
              {fieldLabels.HoaDon_TruocThue}
            </FormLabel>
          </Grid>
          <Grid item xs={4}>
            <MyNumberEditor
              decimalScale={0}
              inputProps={{ min: 0 }}
              value={this.state.after.HoaDon_TruocThue}
              onChange={(value) => {
                this.saveField("HoaDon_TruocThue", value);
              }}
              error={this.state.validateStatus.HoaDon_TruocThue !== undefined}
            />
          </Grid>
          <Grid item xs={2}>
            <FormLabel
              onClick={() =>
                this.setState({
                  show_thongtin_field: true,
                  Show_FieldName: "HoaDon_SauThue",
                })
              }
            >
              {fieldLabels.HoaDon_SauThue}
            </FormLabel>
          </Grid>
          <Grid item xs={4}>
            <MyNumberEditor
              decimalScale={0}
              inputProps={{ min: 0 }}
              value={this.state.after.HoaDon_SauThue}
              onChange={(value) => {
                this.saveField("HoaDon_SauThue", value);
              }}
              error={this.state.validateStatus.HoaDon_SauThue !== undefined}
            />
          </Grid>
        </Grid>
        <Stack spacing={2} direction="row" justifyContent="center" alignItems="center" style={{ marginTop: "10px" }}>
          <Button
            color="success"
            variant="contained"
            disabled={!this.state.dataChanged || this.state.saving}
            onClick={() => this.doSave()}
          >
            {buttonLabels.btnSave}
          </Button>
          {this.state.PhieuGV?.HoaDon_So && <Link
            href="#"
            sx={{ color: "red", zIndex: 0 }}
            onClick={() => {
              this.setState({ showConfirmXoa: true });
            }}
          >
            {buttonLabels.btnDelete}
          </Link>}
        </Stack >
      </Stack>

      {this.state.showConfirmXoa ? (
        <SimpleDialog
          confirm
          message={
            "Bạn muốn xóa hóa đơn: " + this.state.PhieuGV.HoaDon_So + " ?"
          }
          open={true}
          OKRunning={this.state.saving}
          close={(ok) => {
            if (ok) {
              //thuc hien xoa
              this.setState({ saving: true });
              server
                .post("ChiPhi/DoRequest", {
                  Function: "Proc_CP_PhieuGV_HoaDon",
                  ThamSo: {
                    HangMucID: this.state.PhieuGV.HangMucID,
                    HoaDon_So: null,
                    HoaDon_Ngay: null,
                    HoaDon_TruocThue: null,
                    HoaDon_SauThue: null
                  },
                })
                .then(() => {
                  this.setState({ showConfirmXoa: false, saving: false });
                  //reload
                  this.loadData();
                })
                .catch((error) => {
                  this.setState({ saving: false });
                  handleServerError(error);
                });
            } else {
              this.setState({ showConfirmXoa: false });
            }
          }}
        />
      ) : null}
    </>
  }
}


export default withStyles(formStyles, { withTheme: true })(InvoiceTab);