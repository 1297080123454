import * as React from "react";

import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import HandymanIcon from "@mui/icons-material/Handyman";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import WarningIcon from "@mui/icons-material/Warning";
import { Badge } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { ThemeProvider, styled } from "@mui/styles";

import { Navigation } from "react-minimal-side-navigation";
import "./ReactMinimalSideNavigation.css";
//cac form
import LoadingDialog from "../components/LoadingDialog";
import MenuUser from "../components/MenuUser";
import ModuleSelectDialog from "../components/ModuleSelectDialog";
import ReportDataChonDialog from "../danhmuc/ReportDataChonDialog";
import ReportFormChonDialog from "../danhmuc/ReportFormChonDialog";
import CheckRuleChonDialog from "../danhmuc1/CheckRuleChonDialog";
import GraphChonDialog from "../danhmuc1/GraphChonDialog";
import MenuDanhMuc from "../danhmuc1/MenuDanhMuc";
import ShipChonDialog from "../danhmuc1/ShipChonDialog";
import VoyageChonDialog from "../danhmuc1/VoyageChonDialog";
import CodePage from "../pages/CodePage";
import ContractorPage from "../pages/ContractorPage";
import CostPlanPage from "../pages/CostPlanPage";
import JobPage from "../pages/JobPage";
import POPage from "../pages/POPage";
import PriceQuotePage from "../pages/PriceQuotePage";
import RepairRequisitionPage from "../pages/RepairRequisitionPage";
import RepairSpecificationPage from "../pages/RepairSpecificationPage";
import ReportingPage from "../pages/ReportingPage";
import LoginDialog from "../phanquyen/LoginDialog";
///khac
import localConfig from "local-storage";
import { ToastContainer } from "react-toastify";
import { showError } from "../components/common";
import BoPhanDialog from "../phanquyen/BoPhanDialog";
import ChangePassDialog from "../phanquyen/ChangePassDialog";
import NhomQuyenDialog from "../phanquyen/NhomQuyenDialog";
import PhanMemDialog from "../phanquyen/PhanMemDialog";
import UsersPage from "../phanquyen/UsersPage";

import {
  BackupTable,
  Business,
  Code,
  Description,
  Help
} from "@mui/icons-material";
import { Stack } from "@mui/material";
import EmailEditDialog from "../danhmuc1/EmailEditDialog";
import SystemLogsDialog from "../danhmuc1/SystemLogsDialog";
import FileTemplatesPage from "../pages/FileTemplatesPage";
import ShipRepairRequisitionPage from "../pages/ShipRepairRequisitionPage";

const server = require("../lib/server");

const englishUI = true;
function getTitle(page) {
  return page
    ? englishUI
      ? page.titleEN
        ? page.titleEN
        : ""
      : page.title
        ? page.title
        : ""
    : "";
}
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(16)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "left",
  justifyContent: "flex-start",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => {
    return prop !== "open";
  },
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  ...(open && {
    marginLeft: () => {
      return drawerWidth;
    },
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
/* Danh sach form chinh */

const ListOfForms = [
  {
    AccessRight: "R21",
    title: "Yêu cầu sửa chữa từ tàu",
    titleEN: "Ship' Requisition",
    shortTitle: "YCSC từ tàu",
    shortTitleEN: "Ship'ReQ",
    id: "ship-req",
    icon: <AttachEmailIcon color="primary" />,
  },
  {
    AccessRight: "R21",
    title: "Yêu cầu sửa chữa",
    titleEN: "Repair Requisition",
    shortTitle: "YCSC",
    shortTitleEN: "ReQ",
    id: "repair-requisition",
    icon: <HandymanIcon color="primary" />,
  },
  {
    AccessRight: "R21",
    title: "Danh mục công việc",
    titleEN: "List Job",
    shortTitle: "Công việc",
    shortTitleEN: "Jobs",
    id: "job",
    icon: <FormatListNumberedIcon color="primary" />,
  },
  {
    AccessRight: "R21",
    title: "Hạng mục sửa chữa",
    titleEN: "Repair Items (ReS)",
    shortTitle: "HMSC",
    shortTitleEN: "ReS",
    id: "repair-specification",
    icon: <FactCheckIcon color="primary" />,
  },
  {
    AccessRight: "R21",
    title: "Chào giá",
    titleEN: "Price Quote",
    shortTitle: "Chào giá",
    shortTitleEN: "Price",
    id: "price",
    icon: <PriceChangeIcon color="primary" />,
  },
  {
    AccessRight: "R21",
    title: "Phiếu giao việc",
    titleEN: "Purchase order",
    shortTitle: "PGV",
    shortTitleEN: "PO",
    id: "po",
    icon: <Description color="primary" />,
  },
  {
    AccessRight: "R24",
    title: "Thống kê số liệu",
    titleEN: "Expenses Report",
    shortTitle: "Thống kê",
    shortTitleEN: "Reporting",
    id: "chat",
    icon: <RequestQuoteIcon color="primary" />,
    FormName: "DailyShipReportPage",
  },
];
const menu_item_DM_Codes = {
  AccessRight: "R21",
  title: "Code",
  titleEN: "Code",
  shortTitle: "Code",
  shortTitleEN: "Code",
  id: "code",
  icon: <Code color="primary" />,
};
const menu_item_DM_CongTy = {
  AccessRight: "R21",
  title: "Công ty",
  titleEN: "Contractor",
  shortTitle: "C.ty",
  shortTitleEN: "Contractor",
  id: "company",
  icon: <Business color="primary" />,
};
const menu_item_CP_KeHoachNam = {
  AccessRight: "R21",
  title: "Kế hoạch chi phí",
  titleEN: "Budget Plan",
  shortTitle: "Kế hoạch",
  shortTitleEN: "Plan",
  id: "cost-plan",
  icon: <BackupTable color="primary" />,
};
const menu_item_CP_FileTemplate = {
  AccessRight: "R21",
  title: "File mẫu",
  titleEN: "File Template",
  shortTitle: "Template",
  shortTitleEN: "File mẫu",
  id: "file-template",
  icon: <BackupTable color="primary" />,
};
//tao menu theo danh sach tau
function buildNavMenu(danhsach) {
  var items = [];
  danhsach.map((tau) => {
    var subNav = [];
    ListOfForms.map((form) => {
      if (global.hasRoles(form.AccessRight)) {
        subNav.push({
          title: englishUI ? form.titleEN : form.title,
          itemId: tau.ShipID + "/" + form.id,
          elemBefore: () => form.icon,
          ShipData: tau,
          Form: form,
        });
      }
    });
    //co quyen thi moi tao menu
    if (global.hasRoles(tau.ShipCode)) {
      items.push({
        title: tau.ShipName,
        itemId: tau.ShipID + "",
        elemBefore: () =>
          tau.SoYeuCauMoi ? (
            <Badge badgeContent={tau.SoYeuCauMoi} color="error">
              <DirectionsBoatIcon color="primary" />
            </Badge>
          ) : (
            <DirectionsBoatIcon color="primary" />
          ),
        subNav: subNav,
        ShipData: tau,
        Form: subNav.length ? subNav[0].Form : {},
      });
    }
  });
  return items;
}

class MainPageRaw extends React.Component {
  constructor(props) {
    super(props);
    this.mainDiv = React.createRef();
    this.setMainDiv = (element) => {
      this.mainDiv = element;
      if (this.mainDiv) {
        this.setState({
          parentHeight: this.mainDiv.clientHeight,
        });
      }
    };
    this.state = {
      checkingUser: true,
      checkingUserEnd: false,
      currentPage: null,
      drawerOpening: true,
      showDanhMuc: false,
      parentHeight: 200,
      WarningData: {
        ReportNew: 0,
        ReportAlert: 0,
        CheckAlert: 0,
      },
      SystemWarn: false,
      CurrentShip: {
        ShipID: 0,
        ShipCode: "",
        ShipName: "",
      },

      ShipMenuTarget: null, //menu lua chon tau
      DanhSachTau: [],
      DanhSachMenu: [], //danh sach item cau menu theo danh sach tau
      ActiveMenu: null, //menu duoc chon
    };
  }
  componentDidMount() {
    if (!this.state.userChecked) {
      this.doCheckUser();
      this.setState({ userChecked: true });
      //callback neu nhu token expire
      window.onUnAuthorize = () => {
        if (!this.state.showLogin) {
          this.setState({ showLogin: true });
        }
      };
    }
    this.timer = setInterval(this.loadCanhBao, 30000);
    this.loadCanhBao();
  }
  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
  loadCanhBao = () => {
    //load  canh bao du lieu tu may chu
    if (global.User) {
      server
        .post("ChiPhi/DoRequest", {
          Function: "Proc_Get_CanhBao",
          ThamSo: {},
        })
        .then((response) => {
          var list = response.CP_YeuCauSC_Ship;
          this.state.DanhSachMenu.map((menu) => {
            var found = false;
            list.map((item) => {
              if (menu.ShipData.ShipID === item.ShipID) {
                menu.ShipData.SoYeuCauMoi = item.SoYeuCauMoi;
                found = true;
              }
            });
            if (!found) {
              menu.ShipData.SoYeuCauMoi = 0;
            }
          });
          //thiet lap state
          this.setState({ __run: new Date() });
        })
        .catch(() => {
          //console.log(error);
        });
    }
  };
  loadDanhMucShip() {
    //load danh muc
    if (true) {
      server
        .post("ChiPhi/DoRequest", {
          Function: "Proc_DM_Ship_GetAll",
          ThamSo: {},
        })
        .then((response) => {
          //lay cau hinh FM
          // response.DM_Ship.map(
          //   (ship) => (ship.FMEnable = JSON.parse(ship.FMEnable))
          // );
          var menuList = buildNavMenu(response.DM_Ship);
          var lastShipID = localConfig.get("lastShipID");
          lastShipID = lastShipID ?? 0;
          var ship = response.DM_Ship.find((sh) => sh.ShipID === lastShipID);
          var activeMenu =
            menuList.length && menuList[0].subNav.length > 0
              ? menuList[0].subNav[0]
              : {};
          if (ship) {
            //tim menu cua ship
            let item = menuList.find(
              (menu) => menu.ShipData.ShipID === lastShipID
            );
            activeMenu = item.subNav.length ? item.subNav[0] : {};
          }
          this.setState(
            {
              DanhMuc: {
                ...response,
              },
              DanhSachTau: response.DM_Ship,
              CurrentShip: ship
                ? ship
                : response.DM_Ship.length
                  ? response.DM_Ship[0]
                  : {},
              DanhSachMenu: menuList,
              ActiveMenu: activeMenu,
              currentPage: activeMenu.Form ? activeMenu.Form : {},
            },
            () => this.loadCanhBao()
          );
        })
        .catch((error) => {
          showError(error);
        });
    }
  }
  doCheckUser = () => {
    this.setState({
      checkkingUser: true,
      checkingUserEnd: false,
    });
    server
      .query("User/GetUser", {})
      .then((response) => {
        global.User = response;
        this.setState({
          checkingUser: false,
          checkingUserEnd: true,
          userName: response.UserName,
        });
        //hien thi form default
        //this.autoShowMdiForm();
        this.loadDanhMucShip();
      })
      .catch((error) => {
        console.log(error);
        var message = "";
        var log = "";
        try {
          var obj = JSON.parse(error);
          var code = obj.code;
          console.log(obj);
          if (code === 401) {
            //request login

            this.setState({
              checkingUser: false,
              checkingUserEnd: true,
              showLogin: window.myconfig.localLogin,
            });
            //window.location.assign(server.getPortalServer());
          } else {
            log = obj.traceLog;
            var log_detail = JSON.parse(log);
            this.setState({
              checkingUser: true,
              checkingUserEnd: true,
              message: log_detail.Message,
            });
            showError(error);
          }
        } catch (e) {
          message = error + "";
          this.setState({
            checkingUser: true,
            checkingUserEnd: true,
            message: message,
          });
          showError(error);
        }
      });
  };
  handleDrawerOpenClose = () => {
    this.setState({ drawerOpening: !this.state.drawerOpening });
  };
  render() {
    const { theme, classes } = this.props;
    //console.log(this.state.currentPage.FormName);
    if (this.mainDiv) {
      //console.log(this.mainDiv.current);
    }
    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={this.state.drawerOpening}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerOpenClose}
              edge="start"
              sx={{
                marginRight: 5,
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              {getTitle(this.state.currentPage) +
                " - " +
                (this.state.CurrentShip.ShipName
                  ? this.state.CurrentShip.ShipName
                  : "")}
            </Typography>
            <div style={{ marginLeft: "auto" }}>
              {this.state.SystemWarn ? (
                <Badge badgeContent={1} color="error">
                  <IconButton
                    sx={{
                      paddingTop: 0,
                      paddingBottom: 0,
                      padingLeft: 1,
                    }}
                    onClick={() => {
                      this.setState({ show_Logs: true });
                    }}
                  >
                    <WarningIcon style={{ color: "yellow" }} />
                  </IconButton>
                </Badge>
              ) : null}

              <MenuDanhMuc
                onClick={(item) => {
                  console.log(item);
                  if (item.name == "DM_Codes") {
                    this.setState({
                      currentPage: menu_item_DM_Codes,
                    });
                    return;
                  }
                  if (item.name == "DM_CongTy") {
                    this.setState({
                      currentPage: menu_item_DM_CongTy,
                    });
                    return;
                  }
                  if (item.name == "CP_KeHoachNam") {
                    this.setState({
                      currentPage: menu_item_CP_KeHoachNam,
                    });
                    return;
                  }
                  if (item.name == "CP_FileTemplate") {
                    this.setState({
                      currentPage: menu_item_CP_FileTemplate,
                    });
                    return;
                  }
                  var show = {};
                  show["show_" + item.name] = true;
                  this.setState(show);
                }}
              />
              {!this.state.checkingUser && !this.state.showLogin ? (
                <MenuUser
                  UserName={this.state.userName}
                  onClick={(action) => {
                    if (action === "logout") {
                      server.saveToken("", true);
                      this.setState({
                        tabList: [],
                        showLogin: window.myconfig.localLogin,
                        //an cac menu
                        DanhSachTau: [],
                        CurrentShip: {},
                        DanhSachMenu: [],
                        ActiveMenu: {},
                        currentPage: {},
                      });
                      /*
                    var url = server.getPortalServer();
                    if(url) {
                      window.location.assign(url);
                    }*/
                    }
                    if (action === "donvi") {
                      this.setState({ showDonvi: true });
                    }
                    if (action === "phanquyen") {
                      this.setState({ showPhanQuyen: true });
                    }
                    if (action === "phanmem") {
                      this.setState({ showPhanMem: true });
                    }
                    if (action === "nhomquyen") {
                      this.setState({ showNhomQuyen: true });
                    }
                    if (action === "password") {
                      this.setState({ showChangePass: true });
                    }
                  }}
                />
              ) : null}
              <IconButton
                sx={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  padingLeft: 1,
                }}
                onClick={() => {
                  window.open("/help.pdf", "_blank");
                }}
              >
                <Help style={{ color: "white" }} />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={this.state.drawerOpening}>
          <DrawerHeader>
            {this.state.drawerOpening ? (
              <>
                <img
                  src="../logoCVHP.png"
                  alt="Maritime Solution"
                  style={{
                    width: 65,
                    height: 65,
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    this.setState({ show_module_selector: true });
                  }}
                ></img>
                <Stack
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    this.setState({ show_module_selector: true });
                  }}
                >
                  <Typography variant="h6" component="div">
                    QUẢN LÝ
                  </Typography>
                  <Typography variant="h6" component="div">
                    CHI PHÍ
                  </Typography>
                </Stack>
              </>
            ) : null}
          </DrawerHeader>
          <Divider />
          {this.state.drawerOpening ? (
            <Navigation
              // you can use your own router's api to get pathname
              activeItemId={
                this.state.ActiveMenu ? this.state.ActiveMenu.itemId : ""
              }
              onSelect={(item) => {
                // maybe push to the route

                if (item.itemId.indexOf("/") >= 0) {
                  var ShipID = item.itemId.split("/")[0];
                  var find = this.state.DanhSachMenu.find(
                    (c) => c.itemId == ShipID
                  );
                  //tim sub nav
                  var findSub = find.subNav.find(
                    (sub) => sub.itemId == item.itemId
                  );
                  this.setState(
                    {
                      ActiveMenu: item,
                      CurrentShip: findSub.ShipData,
                      currentPage: findSub.Form,
                    },
                    () => localConfig.set("lastShipID", findSub.ShipData.ShipID)
                  );
                } else {
                  //default subnav
                  var find = this.state.DanhSachMenu.find(
                    (c) => c.itemId == item.itemId
                  );
                  this.setState(
                    {
                      ActiveMenu: find.subNav.length ? find.subNav[0] : {},
                      CurrentShip: find.subNav.length
                        ? find.subNav[0].ShipData
                        : {},
                      currentPage: find.subNav.length
                        ? find.subNav[0].Form
                        : {},
                    },
                    () =>
                      localConfig.set(
                        "lastShipID",
                        find.subNav.length ? find.subNav[0].ShipData.ShipID : 0
                      )
                  );
                }
              }}
              items={this.state.DanhSachMenu}
            />
          ) : (
            <List>
              {ListOfForms.map((item) => (
                <ListItemButton
                  key={item.id}
                  selected={item.id == this.state.currentPage.id}
                  style={{ paddingLeft: 0 }}
                  onClick={() => {
                    localConfig.set("currentPage", item);
                    this.setState({ currentPage: item });
                  }}
                >
                  <Box
                    style1={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      alignSelf: "center",
                    }}
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <ListItemIcon
                      display="flex"
                      alignItems="center"
                      flexDirection="column"
                    >
                      {item.icon ? item.icon : <MailIcon color="primary" />}
                    </ListItemIcon>
                    <ListItemText
                      primary={englishUI ? item.shortTitleEN : item.shortTitle}
                      primaryTypographyProps={{ fontSize: "smaller" }}
                    />
                  </Box>
                </ListItemButton>
              ))}
            </List>
          )}

          <Divider />
        </Drawer>
        <Box
          component="div"
          sx={{
            flexGrow: 1,
            p: 1,
            height: window.innerHeight - 75,
          }}
        >
          <DrawerHeader />
          <div className="main" ref={this.setMainDiv}>
            {this.mainDiv ? (
              this.state.currentPage && this.state.currentPage.id ? (
                this.state.currentPage.id == "repair-requisition" ? (
                  <RepairRequisitionPage
                    parentHeight={this.state.parentHeight}
                    ShipData={this.state.CurrentShip}
                  />
                ) : this.state.currentPage.id == "job" ? (
                  <JobPage
                    parentHeight={this.state.parentHeight}
                    ShipData={this.state.CurrentShip}
                  />
                ) : this.state.currentPage.id == "repair-specification" ? (
                  <RepairSpecificationPage
                    parentHeight={this.state.parentHeight}
                    ShipData={this.state.CurrentShip}
                  />
                ) : this.state.currentPage.id == "po" ? (
                  <POPage
                    parentHeight={this.state.parentHeight}
                    ShipData={this.state.CurrentShip}
                  />
                ) : this.state.currentPage.id == "price" ? (
                  <PriceQuotePage
                    parentHeight={this.state.parentHeight}
                    ShipData={this.state.CurrentShip}
                  />
                ) : this.state.currentPage.id == "code" ? (
                  <CodePage parentHeight={this.state.parentHeight} />
                ) : this.state.currentPage.id == "company" ? (
                  <ContractorPage parentHeight={this.state.parentHeight} />
                ) : this.state.currentPage.id == "cost-plan" ? (
                  <CostPlanPage parentHeight={this.state.parentHeight} />
                ) : this.state.currentPage.id == "file-template" ? (
                  <FileTemplatesPage parentHeight={this.state.parentHeight} />
                ) : this.state.currentPage.id == "ship-req" ? (
                  <ShipRepairRequisitionPage
                    parentHeight={this.state.parentHeight}
                    ShipData={this.state.CurrentShip}
                    onChange={() => this.loadCanhBao()}
                  />
                ) : (
                  <ReportingPage
                    parentHeight={this.state.parentHeight}
                    ShipData={this.state.CurrentShip}
                  />
                )
              ) : null
            ) : null}
            {this.state.showDanhMuc ? (
              <ReportDataChonDialog
                open={true}
                disableChon={true}
                close={() => this.setState({ showDanhMuc: false })}
              />
            ) : null}
            {this.state.showLogin ? (
              <LoginDialog
                open={this.state.showLogin}
                close={() => {
                  this.setState(
                    {
                      showLogin: false,
                      userName: global.User.UserName,
                    },
                    () => this.loadDanhMucShip()
                  );
                  //this.autoShowMdiForm(); //bo qua autoshow neu login lai
                }}
              />
            ) : null}
            {this.state.showReportData ? (
              <ReportDataChonDialog
                open={this.state.showReportData}
                disableChon={true}
                close={() => {
                  this.setState({ showReportData: false });
                }}
              />
            ) : null}
            {this.state.showReportForm ? (
              <ReportFormChonDialog
                open={this.state.showReportForm}
                disableChon={true}
                close={() => {
                  this.setState({ showReportForm: false });
                }}
              />
            ) : null}
            {this.state.showPhanMem ? (
              <PhanMemDialog
                open={this.state.showPhanMem}
                close={() => {
                  this.setState({ showPhanMem: false });
                }}
              />
            ) : null}
            {this.state.showDonvi ? (
              <BoPhanDialog
                open={this.state.showDonvi}
                close={() => {
                  this.setState({ showDonvi: false });
                }}
              />
            ) : null}
            {this.state.showPhanQuyen ? (
              <UsersPage
                open={this.state.showPhanQuyen}
                close={() => {
                  this.setState({ showPhanQuyen: false });
                }}
              />
            ) : null}
            {this.state.showNhomQuyen ? (
              <NhomQuyenDialog
                open={this.state.showNhomQuyen}
                close={() => {
                  this.setState({ showNhomQuyen: false });
                }}
              />
            ) : null}

            {this.state.showChangePass ? (
              <ChangePassDialog
                open={this.state.showChangePass}
                close={() => {
                  this.setState({ showChangePass: false });
                }}
              />
            ) : null}
            {this.state.show_DM_Ship ? (
              <ShipChonDialog
                open={this.state.show_DM_Ship}
                disableChon
                close={() => {
                  this.setState({ show_DM_Ship: false });
                }}
              />
            ) : null}
            {this.state.show_DM_Graph ? (
              <GraphChonDialog
                open={this.state.show_DM_Graph}
                disableChon
                close={() => {
                  this.setState({ show_DM_Graph: false });
                }}
              />
            ) : null}
            {this.state.show_DM_Voyage ? (
              <VoyageChonDialog
                open={this.state.show_DM_Voyage}
                disableChon
                close={() => {
                  this.setState({ show_DM_Voyage: false });
                }}
              />
            ) : null}
            {this.state.show_DM_CheckRule ? (
              <CheckRuleChonDialog
                open={this.state.show_DM_CheckRule}
                disableChon
                close={() => {
                  this.setState({ show_DM_CheckRule: false });
                }}
              />
            ) : null}
            {this.state.show_Email ? (
              <EmailEditDialog
                open={this.state.show_Email}
                close={() => {
                  this.setState({ show_Email: false });
                }}
              />
            ) : null}
            {this.state.show_Logs ? (
              <SystemLogsDialog
                open={this.state.show_Logs}
                disableChon={true}
                close={() => {
                  this.setState({ show_Logs: false });
                  this.loadCanhBao();
                }}
              />
            ) : null}
          </div>
        </Box>
        {this.state.checkingUser ? (
          <LoadingDialog
            open={true}
            showRetry={this.state.checkingUserEnd}
            onRetry={() => this.doCheckUser()}
          />
        ) : null}
        {this.state.show_module_selector && (
          <ModuleSelectDialog
            open={true}
            close={() => {
              this.setState({ show_module_selector: false });
            }}
          />
        )}
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
      </Box>
    );
  }
}

//const MainPageRawStyles = withStyles(styles, { withTheme: true })(MiniDrawer);
export default function MainPage() {
  return (
    <ThemeProvider theme={{}}>
      <MainPageRaw />
    </ThemeProvider>
  );
}
