import "@inovua/reactdatagrid-enterprise/index.css";
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormLabel,
  Grid,
  OutlinedInput,
  Stack,
  Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import Dropzone from "react-dropzone";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/*----------------------Cac control trong project su dung den trong form----------------*/
import MyDateEditor from "../../components/MyDateEditor";
import SimpleDialog from "../../components/SimpleDialog";
import {
  formStyles,
  handleServerError,
  showError
} from "../../components/common";
import FieldNameDialog from "../FieldNameDialog";
var numeral = require("numeral");
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../../lib/MyLib");
const server = require("../../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = "Quyết toán ước";
const fieldLabels_EN = {
  NgayQuyetToan: "Date:",
  Attach: "Attach:"
};
const fieldLabels_VN = {
  NgayQuyetToan: "Ngày:",
  Attach: "Ðính kèm:"
};
const fieldLabels = englishUI ? fieldLabels_EN : fieldLabels_VN;
function getFieldNames() {
  var list = [];
  var keys = Object.keys(fieldLabels);
  for (var i = 0; i < keys.length; i++) {
    list.push({
      FieldName: keys[i],
    });
  }
  return list;
}
const listFields = getFieldNames();
const buttonLabels = englishUI
  ? {
    btnSave: "Save",
    btnClose: "Close",
    btnDownload: "Download"
  }
  : {
    btnSave: "Lưu",
    btnClose: "Thoát",
    btnDownload: "Tải về"
  };
/*------------------------------------FORM--------------------------------------*/
class QuyetToanUocDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSaved: false, //du lieu da tung duoc save hay chua
      DanhMuc: { DM_CongTy: [] },
      validateStatus: { error: false }, //trang thai validate
      after: {
        ShipID: this.props.ShipData?.ShipID,
        ShipCode: this.props.ShipData?.ShipCode,
        QuyetToanID: null,
        HangMucID: this.props.HangMucID,
        IsQuyetToan: false,
        SoQuyetToan: "X",
        NgayQuyetToan: new Date(),
        NgaySC1: new Date(),
        NgaySC2: new Date(),
        DiaDiem: null,
        BienBan: null,
        MaCongTy: this.props.MaCongTy,
        LoaiHinhQT: 0,
        ...(props.edittingRecord ? props.edittingRecord : {})
      },
      Attachs: []
    };
  }

  componentDidMount() { }

  saveField = (fieldName, value) => {
    var s = this.state; //new value

    s.after[fieldName] = value;

    s.dataChanged = true;
    s.saving = false;
    this.setState(s);
  };

  getAttachs() {
    //tra ve ten file attachs
    var st = "";
    if (this.state.Attachs) {
      this.state.Attachs.map((att) => {
        st += (st !== "" ? "," : "") + att.FileName;
      });
    }
    return st;
  }

  validate_Data(data) {
    //validate danh sach truong khong duoc trong'
    var ret = myLib.validateFields(data, [
      "NgayQuyetToan"
    ].reduce((obj, item) => Object.assign(obj, { [item]: { "allowEmpty": "false" } }), {}));

    if (this.state.Attachs.length == 0) {
      ret.Attach = true;
    }

    ret.error = Object.keys(ret).length > 0;
    return ret;
  }

  readOneFile(fileData) {
    //tao promies de doc 1 file
    return new Promise((resolve, reject) => {
      if (fileData.File) {
        var fr = new FileReader();
        fr.onload = async (e) => {
          const text = e.target.result;
          fileData.Content = Buffer.from(text).toString("base64");
          fileData.File = undefined; //xoa file di
          resolve(fileData);
        };
        fr.onerror = reject;
        fr.readAsArrayBuffer(fileData.File);
      } else {
        resolve(fileData);
      }
    });
  }
  readFile = (onDone) => {
    var atts = []; // danh sach file dinh kem

    this.state.Attachs.map((file) => {
      const fileData = {
        File: file.File,
        FileID: file.FileID,
        FileName: file.FileName,
        Content: null,
      };
      atts.push(this.readOneFile(fileData));
    });
    Promise.all(atts)
      .then((danhsach) => onDone(danhsach))
      .catch((e) => {
        showError(e);
      }); //doi doc tat ca cac file
  };

  doSave = () => {
    try {
      var check = this.validate_Data(this.state.after);
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        this.readFile((atts) => {
          server
            .post("ChiPhi/DoRequest", {
              Function: "Proc_CP_QuyetToanSC_Upload",
              ThamSo: {
                ...this.state.after,
                FileName: atts.length > 0 ? atts[0].FileName : null,
                Content: atts.length > 0 ? atts[0].Content : null
              }
            })
            .then(() => {
              toast.success("Saved data successfully.");
              this.props.close(true);
            })
            .catch((error) => {
              handleServerError(error);
              this.setState({ saving: false });
            });
        });
      } else {
        this.setState({ validateStatus: check, saving: false });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };

  handleClose = () => {
    if (this.state.dataChanged) {
      this.setState({ showConfirmExit: true });
    } else {
      this.props.close(this.state.dataSaved);
    }
  };
  render() {
    const { classes, theme } = this.props;
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"xl"}
        onClose={() => { }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar sx={{ position: "relative" }}>
          <Stack direction="row">
            <Typography
              sx={{ ml: 2, flex: 1, verticalAlign: "center" }}
              variant="h6"
              component="div"
            >
              {formTitle}
            </Typography>
            <Stack direction="row" spacing={4}>
              {this.state.loading_data ? (
                <CircularProgress color="warning" size={24} />
              ) : null}
              {this.state.saving ? (
                <CircularProgress color="inherit" size={24} />
              ) : null}
              {this.state.loading ? (
                <CircularProgress color="primary" size={24} />
              ) : (
                <></>
              )}
              <Button
                color="success"
                variant="contained"
                disabled={!this.state.dataChanged || this.state.saving}
                onClick={() => this.doSave()}
              >
                {buttonLabels.btnSave}
              </Button>
              <Button
                color="success"
                variant="contained"
                disabled={this.state.saving}
                onClick={this.handleClose}
              >
                {buttonLabels.btnClose}
              </Button>
            </Stack>
          </Stack>
        </AppBar>
        <DialogContent>
          <div
            style={{
              minWidth: 800,
              minHeight: 30,
              padding: 2,
              fontSize: 10,
              wordWrap: "break-word",
              overflow: "hidden",
            }}>
            <Grid container columns={24} rowSpacing={2} columnSpacing={1}>
              <Grid item xs={2}>
                <FormLabel
                  onClick={() =>
                    this.setState({
                      show_thongtin_field: true,
                      Show_FieldName: "NgayQuyetToan",
                    })
                  }
                >
                  {fieldLabels.NgayQuyetToan}
                </FormLabel>
              </Grid>
              <Grid item xs={4}>
                <MyDateEditor
                  fullWidth
                  value={this.state.after.NgayQuyetToan}
                  onChange={(value) => {
                    this.saveField("NgayQuyetToan", value);
                  }}
                  error={this.state.validateStatus.NgayQuyetToan !== undefined}
                />
              </Grid>
              <Grid item xs={18}></Grid>
              <Grid item xs={2}>
                <FormLabel>{fieldLabels.Attach}</FormLabel>
              </Grid>
              <Grid item xs={10}>
                <OutlinedInput
                  fullWidth
                  value={this.getAttachs() || this.state.after.FileName}
                  readOnly
                  placeholder="Quyết toán ước"
                  onChange={() => {
                    this.state.Attachs = [];
                    this.setState({ dataChanged: true, saving: false });
                  }}
                  error={this.state.validateStatus.Attach !== undefined}
                />
              </Grid>
              <Grid item xs={3}>
                <Dropzone
                  maxSize={2 * 1024 * 1024}
                  multiple={false}
                  accept={{ "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [] }}
                  validator={(file) => {
                    if (file.size > 0 && file.size < 2 * 1024 * 1024) {
                      return null;
                    }
                    alert("File too large");
                    return true;
                  }}
                  onDrop={(acceptedFiles) => {
                    if (acceptedFiles.length > 0) {
                      this.state.Attachs = [];
                      acceptedFiles.map((file) => {
                        this.state.Attachs.push({
                          FileName: file.name,
                          File: file,
                        });
                      });
                      this.setState({ dataChanged: true, saving: false });
                    }
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button fullWidth variant="contained">Select file</Button>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  fullWidth
                  disabled={!this.state.after.FileID}
                  href={server.getRequest(`ChiPhi/GetFile?fileID=${this.state.after.FileID}`)}
                  target="_blank"
                >
                  {buttonLabels.btnDownload}
                </Button>
              </Grid>
            </Grid>
          </div>
        </DialogContent>

        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
        <SimpleDialog
          confirm
          message="Bạn chắc chắn muốn thoát ra ?"
          open={this.state.showConfirmExit}
          close={(ok) => {
            this.setState({ showConfirmExit: false });
            if (ok) {
              this.props.close(this.state.dataSaved);
            }
          }}
        />
        {this.state.show_thongtin_field ? (
          <FieldNameDialog
            open={true}
            FormName="QuyetToanUocDialog"
            ListFields={listFields}
            FieldName={this.state.Show_FieldName}
            close={() => {
              this.setState({ show_thongtin_field: false });
            }}
          />
        ) : null}
      </Dialog>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(QuyetToanUocDialog);
